import styled from "styled-components";

export const StyledLeftNav = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #0064fa;
  width: 78px;
  padding-top: 30px;
`;

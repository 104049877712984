import useForm from "app/hooks/useForm";
import { required } from "app/utils/validator";
import {
  LoginFormFieldsEnum,
  LoginFormFieldsInterface,
} from "../types/login-form-fields";

const requireValidator = (value: string | undefined) => required(value);

export const useLoginForm = (initialValues?: LoginFormFieldsInterface) => {
  const { values, useField, meta } =
    useForm<LoginFormFieldsInterface>(initialValues);

  return {
    meta,
    values,
    fields: {
      [LoginFormFieldsEnum.USERNAME]: useField(
        LoginFormFieldsEnum.USERNAME,
        requireValidator
      ),
      [LoginFormFieldsEnum.PASSWORD]: useField(
        LoginFormFieldsEnum.PASSWORD,
        requireValidator
      ),
    },
  };
};

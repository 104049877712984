export const required = (value: string | undefined) => {
  if (!value) {
    return "Required";
  }
};

export const stringWithNumbers = (value: string | undefined) => {
  if (!value) {
    return "Required";
  }

  if (!value.match(/^[0-9]+$/g)) {
    return "Should be numbers";
  }
};

export const requiredFile = (value: Blob | undefined) => {
  if (!value) {
    return "Required";
  }
};

export const isInt = (value: string | undefined) => {
  if (value && (!Number.isInteger(+value) || +value < 0)) {
    return "Should be integer more than 0";
  }
};

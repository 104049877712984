import React, { useState, FormEvent, ChangeEvent } from "react";
import { Box, Dialog, FormControl } from "@material-ui/core";

import { StyledDialogTitle } from "app/components/info-dialog/dialogTitle";
import { SubmitForm } from "app/components/submit-form";
import { Form } from "app/components/form";
import { EventMessage, EventGift, isMessagesEvent } from "./types";
import { FormRadio } from "shards-react";
import { LinkText, StyledSelect, CancelButton, Icon } from "./styles";
import minus from "app/asset/icons/minus.svg";
import plus from "app/asset/icons/plus.svg";
import { uniqueId, omit } from "lodash";
import { MessageInputs } from "./components/message-inputs";
import { GiftEvent } from "app/components/add-bot/components/gift-event";
import { useAppSelector } from "app/store-setup/utils";
import { Person } from "feature/person/models";
import { EventTypeEnum, IBotEvent } from "feature/bot/api/types";
import { Persons } from "feature/person/store/types";

interface EventModalProps {
  isOpen: boolean;
  onClose(): void;
  initValues?: IBotEvent | EventMessage | EventGift | null;
  setEvents?: (events: any) => void;
  index?: number;
  mainButtonText: string;
}

export const EventModal: React.FC<EventModalProps> = ({
  isOpen,
  onClose,
  initValues,
  setEvents,
  mainButtonText,
  index,
}) => {
  const [selectedType, setSelectedType] = useState<EventTypeEnum>(
    EventTypeEnum.Message
  );
  const persons: Persons = useAppSelector((store) => store?.person?.persons);
  const [messages, setMessages] = useState(
    initValues && isMessagesEvent(initValues) && initValues?.messages
      ? initValues.messages.reduce(
          (acc, curr) => ({
            ...acc,
            [uniqueId()]: curr,
          }),
          {}
        )
      : {
          [uniqueId()]: "",
        }
  );

  const [selectedPersons, setSelectedPersons] = useState<{
    [id: string]: {
      avatarUrl: string;
      name: string;
      id: number;
    };
  }>({
    [uniqueId()]: {
      avatarUrl:
        initValues && initValues.personId
          ? persons[initValues.personId].avatarUrl
          : Object.values(persons)[0]?.avatarUrl,
      name:
        initValues && initValues.personId
          ? persons[initValues.personId].name
          : Object.values(persons)[0]?.name,
      id:
        initValues && initValues.personId
          ? persons[initValues.personId].id
          : Object.values(persons)[0]?.id,
    },
  });
  const [selectedGifts, setSelectedGifts] = useState<number[]>([]);

  const handleSubmit = (e: FormEvent<HTMLElement>) => {
    let request: EventGift | EventMessage;
    if (selectedType === EventTypeEnum.Message)
      request = {
        messages: Object.values(messages).length
          ? Object.values(messages)
          : undefined,
        personId: Object.values(selectedPersons).map((person) =>
          Number(person.id)
        )[0],
        delay: 0,
        type: selectedType,
      };
    else {
      request = {
        gifts: selectedGifts.length ? selectedGifts : [],
        personId: Object.values(selectedPersons).map((person) =>
          Number(person.id)
        )[0],
        delay: 0,
        type: selectedType,
      };
    }

    if (setEvents) {
      if (initValues) {
        setEvents((prevValues: Array<IBotEvent>) => {
          const test = prevValues.map((event, eIndex) =>
            eIndex === index
              ? {
                  ...event,
                  messages: Object.values(messages),
                  gifts: selectedGifts,
                  personId: Object.values(selectedPersons).map((person) =>
                    Number(person.id)
                  )[0],
                }
              : event
          );
          return test;
        });
      } else {
        setEvents((prevValues: Array<EventMessage | EventGift>) => [
          ...prevValues,
          request,
        ]);
      }
    }
    e.preventDefault();
    onClose();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    setMessages((prevValue) => ({
      ...prevValue,
      [key]: e.target.value,
    }));
  };
  const handleDeleteInput = (inputId: string) => {
    setMessages((prevValues) => omit(prevValues, inputId));
  };

  const handleAddSmile = (messages: { [key: string]: string }) => {
    setMessages(messages);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <Box sx={{ m: 2 }} width={360} height={564}>
        <FormControl component="fieldset">
          <StyledDialogTitle>Событие</StyledDialogTitle>
          <Box m={2} width="100%">
            <Box display="flex" alignItems="center">
              <FormRadio
                name="type"
                checked={selectedType === EventTypeEnum.Message}
                onChange={() => {
                  setSelectedType(EventTypeEnum.Message);
                }}
              >
                Сообщения
              </FormRadio>
              <Box ml={4}>
                <FormRadio
                  name="type"
                  checked={selectedType === EventTypeEnum.Event}
                  onChange={() => {
                    setSelectedType(EventTypeEnum.Event);
                  }}
                >
                  Подарки
                </FormRadio>
              </Box>
            </Box>
            {selectedType === EventTypeEnum.Message ? (
              <>
                <MessageInputs
                  messages={messages}
                  handleChangeInput={handleChangeInput}
                  handleDeleteInput={handleDeleteInput}
                  handleAddSmile={handleAddSmile}
                />
                <LinkText
                  onClick={() => {
                    setMessages({ ...messages, [uniqueId()]: "" });
                  }}
                >
                  + Добавить сообщение
                </LinkText>
              </>
            ) : (
              <GiftEvent
                selectedGifts={selectedGifts}
                setSelectedGifts={setSelectedGifts}
              />
            )}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <h4>Личность</h4>
              <Icon
                src={plus}
                onClick={() => {
                  setSelectedPersons({
                    ...selectedPersons,
                    [uniqueId()]: {
                      avatarUrl: "",
                      name: "",
                      id: Object.values(persons)[0].id,
                    },
                  });
                }}
              />
            </Box>
            {Object.keys(selectedPersons).map((key, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                my={2}
                key={key}
              >
                <StyledSelect
                  placeholder="Выберите личность"
                  value={Object.values(selectedPersons)[0].id}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSelectedPersons({
                      ...selectedPersons,
                      [key]: {
                        ...selectedPersons[key],
                        id: Number(e?.target?.value) || 0,
                      },
                    });
                  }}
                >
                  {Object.values(persons).map((person: Person) => (
                    <option value={person.id}>{person.name}</option>
                  ))}
                </StyledSelect>
                {index !== 0 && (
                  <Icon
                    src={minus}
                    onClick={() => setSelectedPersons(omit(persons, key))}
                  />
                )}
              </Box>
            ))}
            <Form onSubmit={handleSubmit}>
              <Box
                display="flex"
                alignItems="center"
                alignSelf="self-end"
                mt={3}
              >
                <CancelButton onClick={onClose}>Отменить</CancelButton>
                <SubmitForm>
                  <Box>{mainButtonText}</Box>
                </SubmitForm>
              </Box>
            </Form>
          </Box>
        </FormControl>
      </Box>
    </Dialog>
  );
};

import React, { useState } from "react";

import { EntityListResponse } from "app/api/types";
import { SearchFormFieldsInterface } from "../search/types/SearchFormFields";
import {
  AddButton,
  Container,
  Content,
  StyledPagination,
  TableSwitchButton,
  Tools,
} from "./styles";
import { Box } from "@material-ui/core";
import addIcon from "app/asset/icons/addIcon.svg";
import { useAppDispatch, useAppSelector } from "app/store-setup/utils";
import { selectActiveTab, setActiveTab } from "app/store";
import { Link } from "react-router-dom";
import { Search } from "../search";
import { PersonModal } from "./components/person-modal";

interface TabWrapperProps {
  list: OrUndefined<EntityListResponse<any>>;
  page: number;
  onPageChange(page: number): void;
  onSearchSubmit(values: SearchFormFieldsInterface): void;
}

export const TabWrapper: React.FC<TabWrapperProps> = ({
  list,
  page,
  onPageChange,
  onSearchSubmit,
  children,
}) => {
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isPersonModalVisible, setIsPersonModalVisible] =
    useState<boolean>(false);
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();

  const handleSearchChange = () => {
    isReset && setIsReset(false);
  };

  const handleClickSwitch = (index: number) => {
    if (activeTab !== index) dispatch(setActiveTab(index));
  };

  const isBotTable = activeTab === 0;

  const handlePersonModalClose = () => {
    setIsPersonModalVisible(false);
    isReset && setIsReset(false);
  };
  return (
    <>
      <PersonModal
        isOpen={isPersonModalVisible}
        onClose={handlePersonModalClose}
      />
      <Container>
        <Tools>
          <Box display="flex">
            <TableSwitchButton
              onClick={() => handleClickSwitch(0)}
              isActive={activeTab === 0}
            >
              Боты
            </TableSwitchButton>
            <Box ml={1}>
              <TableSwitchButton
                onClick={() => handleClickSwitch(1)}
                isActive={activeTab === 1}
              >
                Личности
              </TableSwitchButton>
            </Box>
            {isBotTable ? (
              <Link to="addBot">
                <AddButton>
                  {<img src={addIcon} />} <Box ml={2}>Добавить бота</Box>
                </AddButton>
              </Link>
            ) : (
              <AddButton onClick={() => setIsPersonModalVisible(true)}>
                {<img src={addIcon} />} <Box ml={2}>Добавить личность</Box>
              </AddButton>
            )}
          </Box>
          <Search
            isReset={isReset}
            onSubmit={onSearchSubmit}
            onChange={handleSearchChange}
          />
        </Tools>
        <Content>{children}</Content>
        <StyledPagination
          page={page}
          onChange={(_event, page) => onPageChange(page)}
          count={list?.totalPages}
        />
      </Container>
    </>
  );
};

import { Box } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { TextArea } from "../card-event/styles";
import { SmileString } from "../smile-string";

interface MessageEventProps {
  handleChangeMessage: (
    e: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  messages: string[];
  handleAddSmile: (messages: string[]) => void;
}

export const MessageEvent: React.FC<MessageEventProps> = ({
  messages,
  handleChangeMessage,
  handleAddSmile,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {messages.map((message: string, index: number) => (
        <div key={`${messages.length} - ${index}`}>
          <SmileString
            handleChange={(char: string) =>
              handleAddSmile(
                messages.map((message, mIndex) =>
                  index === mIndex ? message + char : message
                )
              )
            }
          />
          <Box>
            <TextArea
              placeholder="Follow-up text"
              value={message}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChangeMessage(e, index)
              }
            />
          </Box>
        </div>
      ))}
    </Box>
  );
};

import { api } from "app/api";
import { TokenResponse } from "app/feature/auth/types";
import { LoginFormFieldsInterface } from "app/feature/auth/types/login-form-fields";

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    auth: build.mutation<string, LoginFormFieldsInterface>({
      query: (loginFormFields: LoginFormFieldsInterface) => ({
        url: "/auth/admin",
        method: "post",
        body: loginFormFields,
      }),
      transformResponse: (response: TokenResponse) => response.token,
    }),
  }),
  overrideExisting: false,
});

export const { useAuthMutation } = authApi;

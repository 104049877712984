import React from "react";
import { Route, Redirect } from "react-router-dom";

import { appRoutes } from "./appRoutes";

interface PrivateRouteProps {
  component: React.FC;
  path: string;
  isLogin: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  path,
  isLogin,
}) => (
  <Route exact path={path}>
    {isLogin ? <Component /> : <Redirect to={appRoutes.auth} />}
  </Route>
);

export default PrivateRoute;

import AppTypes from "AppTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Bot } from "feature/bot/models";
import { BotSlice } from "./types";

const initialState: BotSlice = {
  bot: null,
};

const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    setBot: (state, action: PayloadAction<Bot>) => {
      state.bot = action.payload;
    },
  },
});

export const { setBot } = botSlice.actions;

export const selectBot = (state: AppTypes.Redux.AppRootState) => state.bot;

export const botReducer = botSlice.reducer;

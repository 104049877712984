import { createGlobalStyle } from "styled-components";

export const AppGlobalStyles = createGlobalStyle`
  body, html, #root {
    height: 100%;
    font-family: ${({ theme }) => theme.fontFamily.sfProDisplay} !important;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

import moment from "moment";
import { Box } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { Audience } from "./components/audience";
import {
  Events,
  MainButton,
  NameInput,
  PauseTimelineDot,
  StyledTimelineItem,
} from "./styles";
import addIcon from "app/asset/icons/addIcon.svg";
import { EventModal } from "../event-modal";
import { EventMessage, EventGift } from "../event-modal/types";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useAppSelector } from "app/store-setup/utils";
import { selectBot } from "feature/bot/store";
import pauseIcon from "app/asset/icons/pauseIcon.svg";
import { CardChangeEvent } from "./components/card-change-event";
import { Footer } from "../footer";
import { WorkSchedule } from "../work-schedule";
import { ScheduleDatePicker } from "../schedule-date-picker";
import { useUpdateBotMutation } from "feature/bot/api";
import { useHistory } from "react-router-dom";
import { IBotEvent, UpdateBotFieldsEnum } from "feature/bot/api/types";
import { isEmpty } from "lodash";
import { NumberInput } from "../styles";

export const EditBot: React.FC = () => {
  const { bot } = useAppSelector(selectBot);
  const [everyDay, setEveryDay] = useState(bot?.schedule.isEveryDay || false);
  const [isAllDay, setAllDay] = useState(bot?.schedule.isAllDay || false);
  const [events, setEvents] = useState<IBotEvent[]>(bot?.events || []);
  const [editEvent, setEditEvent] = useState<IBotEvent | null>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [firstStream, setFirstStream] = useState<number | null>(
    bot?.firstStream || null
  );
  const [diamondsAmount, setDiamondsAmount] = useState<string | null>(
    bot?.diamondsAmount || null
  );
  const [viewersAmount, setViewersAmount] = useState<string | null>(
    bot?.viewersAmount || null
  );
  const [giftsAmount, setGiftsAmount] = useState<string | null>(
    bot?.giftsAmount || null
  );
  const [name, setName] = useState<string>(bot?.name || "");
  const [isEventModalOpen, setIsOpenModalOpen] = useState(false);
  const [updateBot, { isLoading, error }] = useUpdateBotMutation();
  const history = useHistory();

  const handleChangeWorkSchedule = () => {
    setEveryDay((prevVal) => !prevVal);
  };

  const handleChange =
    (setState: React.Dispatch<React.SetStateAction<string | null>>) =>
    (value: string | null) => {
      setState(value);
    };

  const handleChangeFirstStream = (value: number | null) => {
    setFirstStream(value);
  };

  const handleEventModalClose = () => {
    setIsOpenModalOpen(false);
  };

  const handleSubmit = () => {
    if (bot?.id) {
      const request = {
        [UpdateBotFieldsEnum.ID]: bot.id,
        [UpdateBotFieldsEnum.NAME]: name,
        [UpdateBotFieldsEnum.SCHEDULE]: {
          from: isAllDay ? moment().utc().startOf("day").toISOString() : "",
          to: isAllDay ? moment().utc().endOf("day").toISOString() : "",
          isAllDay,
          isEveryDay: everyDay,
        },
        [UpdateBotFieldsEnum.FIRST_STREAM]: firstStream || null,

        [UpdateBotFieldsEnum.DIAMONDS_AMOUNT]: diamondsAmount || null,

        [UpdateBotFieldsEnum.VIEWERS_AMOUNT]: viewersAmount || null,

        [UpdateBotFieldsEnum.GIFTS_AMOUNT]: giftsAmount || null,

        [UpdateBotFieldsEnum.EVENTS]: events,
      };

      updateBot(request).then(() => {
        history.push("/");
      });
    }
  };

  const handleSwitchAllDay = () => {
    setAllDay((prevValue) => !prevValue);
  };

  return (
    <>
      {isEventModalOpen && (
        <EventModal
          isOpen={isEventModalOpen}
          onClose={handleEventModalClose}
          setEvents={setEvents}
          initValues={isEmpty(editEvent) ? null : editEvent}
          mainButtonText={isEmpty(editEvent) ? "Создать" : "Обновить"}
          index={editIndex || undefined}
        />
      )}
      <Events>
        <Box width="593px" mb={10}>
          <NameInput
            placeholder="Название"
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
          <WorkSchedule
            isEveryDay={everyDay}
            onChange={handleChangeWorkSchedule}
          />
          {!everyDay ? (
            <ScheduleDatePicker
              isAllDay={isAllDay}
              handleSwitchAllDay={handleSwitchAllDay}
            />
          ) : (
            ""
          )}
          <Audience
            setDiamondsAmount={handleChange(setDiamondsAmount)}
            setViewersAmount={handleChange(setViewersAmount)}
            setGiftsAmount={handleChange(setGiftsAmount)}
            setFirstStream={handleChangeFirstStream}
            diamondsAmount={diamondsAmount}
            viewersAmount={viewersAmount}
            giftsAmount={giftsAmount}
            firstStream={firstStream}
          />
        </Box>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={4}
        >
          <h1>События</h1>
          <Timeline>
            {events.map((event, index) => (
              <div key={index}>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector color="primary" />
                  </TimelineSeparator>
                  <TimelineContent>
                    <CardChangeEvent
                      messages={event?.messages || undefined}
                      type={event.type}
                      gifts={event?.gifts || undefined}
                      delay={0}
                      personIds={event?.personId ? [event.personId] : []}
                      handleClickEdit={() => {
                        setEditEvent(event);
                        setEditIndex(index);
                        setIsOpenModalOpen(true);
                      }}
                    />
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <PauseTimelineDot color="primary" variant="outlined">
                      <img width={8} height={8} src={pauseIcon} />
                    </PauseTimelineDot>
                    <TimelineConnector color="primary" />
                  </TimelineSeparator>
                  <Box width="100%" py={2}>
                    <TimelineContent>
                      <NumberInput
                        id="minutes"
                        type="number"
                        defaultValue="00"
                        label="Минуты"
                        value={Math.floor(
                          moment.duration(event.delay).asSeconds() / 60
                        )}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setEvents((prevEvents) =>
                            prevEvents.map((prevEvent, prevEventIndex) =>
                              prevEventIndex === index
                                ? {
                                    ...prevEvent,
                                    delay:
                                      (prevEvent.delay % 1000) +
                                      Number(e.target.value) * 60000,
                                  }
                                : prevEvent
                            )
                          );
                        }}
                      />
                      <NumberInput
                        id="seconds"
                        type="number"
                        label="Секунды"
                        defaultValue="00"
                        ml="40px"
                        value={moment.duration(event.delay).asSeconds() % 60}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEvents((prevEvents) =>
                            prevEvents.map((prevEvent, prevEventIndex) =>
                              index === prevEventIndex
                                ? {
                                    ...prevEvent,
                                    delay:
                                      Math.floor(prevEvent.delay / 60000) *
                                        60000 +
                                      Number(e.target.value) * 1000,
                                  }
                                : prevEvent
                            )
                          )
                        }
                      />
                    </TimelineContent>
                  </Box>
                </StyledTimelineItem>
              </div>
            ))}
            <StyledTimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector color="primary" />
              </TimelineSeparator>
              <TimelineContent>
                <MainButton onClick={() => setIsOpenModalOpen(true)}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {<img src={addIcon} />} <Box ml={2}>Добавить событие</Box>
                  </Box>
                </MainButton>
              </TimelineContent>
            </StyledTimelineItem>
          </Timeline>
        </Box>
      </Events>
      <Footer mainButtonText="Сохранить" handleSubmit={handleSubmit} />
    </>
  );
};

import React, { useEffect } from "react";

import { useAppDispatch } from "app/store-setup/utils";
import { getToken } from "app/storage";
import { setIsLogin } from "app/feature/auth/store";
import { Container, Content } from "./styles";

export const Layout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const token = getToken();

  useEffect(() => {
    if (token) {
      dispatch(setIsLogin(true));
    }
  }, [token, dispatch]);

  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

import { Box, Switch } from "@material-ui/core";
import React from "react";

interface WorkScheduleProps {
  onChange(): void;
  isEveryDay: boolean;
}

export const WorkSchedule: React.FC<WorkScheduleProps> = ({
  onChange,
  isEveryDay,
}) => {
  return (
    <Box mt={2} display="flex" justifyContent="space-between">
      <h3>График работы</h3>
      <span>
        Каждый день
        <span>
          <Switch checked={isEveryDay} onChange={onChange} />
        </span>
      </span>
    </Box>
  );
};

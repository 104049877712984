import { api } from "app/api";
import { EntityListRequest, EntityListResponse } from "app/api/types";
import { urlGenerator } from "app/utils/urlGenerator";
import { getParams } from "app/utils/getParams";
import { Bot } from "feature/bot/models";
import { CreateBotFieldsInterface } from "./types";
import { UpdateBotFieldsInterface } from "./types/update-bot";

const botsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBots: build.query<EntityListResponse<Bot>, EntityListRequest>({
      query: (request) => {
        const params = getParams(request);

        return {
          url: urlGenerator(`/bots/${request.page}`, params),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.list.map(({ id }) => ({ type: "Bot", id } as const)),
              "Bot",
            ]
          : ["Bot"],
    }),
    archiveBot: build.mutation<void, number>({
      query: (id) => ({
        url: `/bots/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Bot"],
    }),
    createBot: build.mutation<string, CreateBotFieldsInterface>({
      query: (createBotFields: CreateBotFieldsInterface) => ({
        url: "/bots",
        method: "post",
        body: createBotFields,
      }),
      invalidatesTags: ["Bot"],
    }),
    updateBot: build.mutation<string, UpdateBotFieldsInterface>({
      query: (updateBotFields: UpdateBotFieldsInterface) => ({
        url: `/bots`,
        method: "put",
        body: updateBotFields,
      }),
      invalidatesTags: ["Bot"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBotsQuery,
  useArchiveBotMutation,
  useCreateBotMutation,
  useUpdateBotMutation,
} = botsApi;

import React from "react";

import { useAppSelector } from "app/store-setup/utils";
import { selectActiveTab } from "app/store";
import { TabPanel } from "app/components/tab/tabPanel";
import { BotTab } from "feature/bot/components";
import { PersonTab } from "feature/person/components";
import { Box } from "@material-ui/core";

export const Main: React.FC = () => {
  const activeTab = useAppSelector(selectActiveTab);

  return (
    <>
      <Box mt={3}>
        <h1>Боты</h1>
        <TabPanel value={activeTab} index={0}>
          <BotTab />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <PersonTab />
        </TabPanel>
      </Box>
    </>
  );
};

import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useAppDispatch } from "app/store-setup/utils";
import { MainButton } from "../button/main";
import {
  HeaderContainer,
  LeftContent,
  RightContent,
  StyledNavbar,
} from "./styles";
import leftArrow from "app/asset/icons/leftArrow.svg";
import { Box } from "@material-ui/core";
import { RegularText } from "../add-bot/components/card-event/styles";
import { NavbarBrand } from "shards-react";
import { setIsLogin } from "app/feature/auth/store";
import { resetToken } from "app/storage";

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoginScreen = location.pathname === "/auth";
  const isBotsScreen = location.pathname === "/";
  const handleLogoutClick = () => {
    dispatch(setIsLogin(false));
    resetToken();
  };

  return (
    <>
      <StyledNavbar expand="md" background={!isBotsScreen && "white"}>
        <NavbarBrand href="#">
          {!isBotsScreen && !isLoginScreen && (
            <Link to="/">
              <LeftContent>
                <Box>{<img src={leftArrow} />}</Box>
                <Box ml={1}>
                  <RegularText color="#B3B3B3">Bots</RegularText>
                </Box>
              </LeftContent>
            </Link>
          )}
        </NavbarBrand>
        <HeaderContainer isActive={!isLoginScreen}>
          <RightContent>
            <MainButton onClick={handleLogoutClick}>Logout</MainButton>
          </RightContent>
        </HeaderContainer>
      </StyledNavbar>
    </>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import { config } from "./app/config";
import { AppGlobalStyles } from "./app/style/globalStyles";
import { store } from "./app/store-setup";
import theme from "./app/style/theme";
import { App } from "./App";
import "./app/style/fonts.css";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppGlobalStyles />
      <BrowserRouter basename={config.basename}>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

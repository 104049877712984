import { Box } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { NumberInput } from "app/components/styles";

interface SettingProps {
  amount: string | null;
  setAmount: (value: string) => void;
}
export const Setting: React.FC<SettingProps> = ({ amount, setAmount }) => {
  const [from, to] = amount ? amount.split("-") : ["0", "0"];

  const handleChangeFrom = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) >= 0) setAmount(`${e.target.value}-${to}`);
  };

  const handleChangeTo = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) >= 0) setAmount(`${from}-${e.target.value}`);
  };

  return (
    <Box mb={4} display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box>От</Box>
        <NumberInput
          type="number"
          label="От"
          ml="20px"
          value={from || 0}
          onChange={handleChangeFrom}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box>До</Box>
        <NumberInput
          type="number"
          ml="20px"
          label="До"
          value={to || 0}
          onChange={handleChangeTo}
        />
      </Box>
    </Box>
  );
};

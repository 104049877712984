const theme = {
  colors: {
    c1: "#130F26",
    c2: "#FF4A39",
    c3: "#FFFFFF",
    c4: "#F8F6FF",
    c5: "#6E3AFF",
    c6: "#99A2BA",
  },
  fontFamily: {
    sfProDisplay: "SF Pro Display",
    sfProDisplaySemibold: "SF Pro Display semibold",
    sfProDisplayBold: "SF Pro Display bold",
  },
};

export default theme;

import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)`
  label,
  input {
    font-family: ${({ theme }) => theme.fontFamily.sfProDisplay};
    color: ${({ theme }) => theme.colors.c6} !important;
  }
`;

import { Box, Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { TextDescription } from "./styles";
import React from "react";
import { isNumber, isString } from "lodash";
import { Setting } from "./components/setting";
import { FirstStreamSetting } from "./components/first-stream-setting";

interface AudienceProps {
  diamondsAmount: string | null;
  giftsAmount: string | null;
  viewersAmount: string | null;
  firstStream: number | null;
  setDiamondsAmount: (value: string | null) => void;
  setGiftsAmount: (value: string | null) => void;
  setViewersAmount: (value: string | null) => void;
  setFirstStream: (value: number | null) => void;
}
export const Audience: React.FC<AudienceProps> = ({
  diamondsAmount,
  setDiamondsAmount,
  giftsAmount,
  viewersAmount,
  firstStream,
  setGiftsAmount,
  setViewersAmount,
  setFirstStream,
}) => {
  const handleFirstStreamChange = () => {
    const value = isNumber(firstStream) ? null : 0;
    setFirstStream(value);
  };

  const handleDiamondsAmountChange = () => {
    const value =
      diamondsAmount && diamondsAmount.split("-").length === 2 ? null : "-";
    setDiamondsAmount(value);
  };

  const handleGiftsAmountChange = () => {
    const value =
      giftsAmount && giftsAmount.split("-").length === 2 ? null : "-";
    setGiftsAmount(value);
  };

  const handleViewersChange = () => {
    const value =
      viewersAmount && viewersAmount.split("-").length === 2 ? null : "-";
    setViewersAmount(value);
  };

  return (
    <Box mt={2}>
      <TextDescription>
        Выберите условия, при выполнения которых бот будет работать с выбранным
        стримером
      </TextDescription>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleFirstStreamChange}
              checked={isNumber(firstStream)}
            />
          }
          label="Первый стрим"
        />
        {isNumber(firstStream) ? (
          <FirstStreamSetting setAmount={setFirstStream} amount={firstStream} />
        ) : (
          ""
        )}
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleDiamondsAmountChange}
              checked={isString(diamondsAmount)}
            />
          }
          label="Количество брилиантов"
        />
        {isString(diamondsAmount) ? (
          <Setting setAmount={setDiamondsAmount} amount={diamondsAmount} />
        ) : (
          ""
        )}
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleViewersChange}
              checked={isString(viewersAmount)}
            />
          }
          label="Количество зрителей"
        />
        {isString(viewersAmount) ? (
          <Setting setAmount={setViewersAmount} amount={viewersAmount} />
        ) : (
          ""
        )}
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleGiftsAmountChange}
              checked={isString(giftsAmount)}
            />
          }
          label="Количество подаренных подарков"
        />
        {isString(giftsAmount) ? (
          <Setting setAmount={setGiftsAmount} amount={giftsAmount} />
        ) : (
          ""
        )}
      </FormGroup>
    </Box>
  );
};

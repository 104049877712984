import styled from "styled-components";
import SettingIcon from "@material-ui/icons/Settings";
import { Navbar } from "shards-react";

export const HeaderContainer = styled.header<{ isActive: boolean }>`
  position: static;
  padding: 16px;
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
`;

export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const RightContent = styled.div`
  margin-left: auto;
`;

export const Settings = styled(SettingIcon)`
  margin-right: 20px;
  fill: ${({ theme }) => theme.colors.c5} !important;
`;

export const StyledNavbar = styled(Navbar)<{ background?: string }>`
  display: flex;
  align-items: center;
  background: ${({ background }) => (background ? background : "white")};
  justify-content: space-between;
  box-shadow: 0px 1px 12px rgba(226, 230, 229, 0.598995);
  padding: 0 0 0 40px;
`;

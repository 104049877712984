import React from "react";
import { useGetGiftsQuery } from "feature/gift/api";
import { Badge, GiftsBlock } from "../../styles";

interface CardChangeEventProps {
  gifts: number[];
}

export const Gifts: React.FC<CardChangeEventProps> = ({ gifts }) => {
  const { data: giftList } = useGetGiftsQuery();

  return (
    <GiftsBlock>
      {gifts?.map((giftId) => {
        const gift = giftList?.list.find((gift) => gift.id === giftId);
        return gift ? (
          <Badge key={giftId}>{<img src={gift.image} />}</Badge>
        ) : (
          <></>
        );
      })}
    </GiftsBlock>
  );
};

import React, { useEffect } from "react";
import { Layout } from "./app/components/layout";
import { Header } from "./app/components/header";
import { Router } from "./app/router";
import { Box } from "@material-ui/core";
import { LeftNav } from "./app/components/left-nav";
import { useGetPersonsQuery } from "feature/person/api";
import { useDispatch } from "react-redux";
import useTabContent from "app/hooks/useTabContent";
import { setPersons } from "feature/person/store";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { page, order, direction, search, filter, handlers } = useTabContent();
  const { data: personList } = useGetPersonsQuery({
    page,
    order,
    direction,
    search,
    filter,
  });

  useEffect(() => {
    if (personList) {
      dispatch(setPersons(personList.list));
    }
  }, [personList]);
  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <LeftNav />
        <Box width="100%">
          <Header />
          <Router />
        </Box>
      </Box>
    </Layout>
  );
};

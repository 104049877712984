import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { LoginForm } from "app/feature/auth/components/login-form";
import { selectIsLogin } from "app/feature/auth/store";
import { useAppSelector } from "app/store-setup/utils";
import { AddBot } from "app/components/add-bot";
import { appRoutes } from "app/router/appRoutes";
import PrivateRoute from "app/router/private-route";
import { Main } from "app/components/main";
import { Person } from "app/components/person";
import { EditBot } from "app/components/edit-bot";

export const Router: React.FC = () => {
  const isLogin = useAppSelector(selectIsLogin);

  return (
    <Switch>
      <Route exact path={appRoutes.auth}>
        {!isLogin ? <LoginForm /> : <Redirect to={appRoutes.index} />}
      </Route>
      <PrivateRoute
        path={appRoutes.addBot}
        isLogin={isLogin}
        component={AddBot}
      />
      <PrivateRoute
        path={appRoutes.botEdit}
        isLogin={isLogin}
        component={EditBot}
      />
      <PrivateRoute
        path={appRoutes.person}
        isLogin={isLogin}
        component={Person}
      />
      <PrivateRoute path={appRoutes.index} isLogin={isLogin} component={Main} />
    </Switch>
  );
};

import { EventGift, EventMessage } from "app/components/event-modal/types";

export enum CreateBotFieldsEnum {
  NAME = "name",
  SCHEDULE = "schedule",
  FIRST_STREAM = "firstStream",
  DIAMONDS_AMOUNT = "diamondsAmount",
  VIEWERS_AMOUNT = "viewersAmount",
  GIFTS_AMOUNT = "giftsAmount",
  EVENTS = "events",
}

export interface IBotSchedule {
  from: string;
  to: string;
  isAllDay: boolean;
  isEveryDay: boolean;
}

export enum EventTypeEnum {
  Message = "message",
  Event = "event",
}
export interface IBotEvent {
  id: number;
  type: EventTypeEnum;
  delay: number;
  variability: boolean;
  messages: string[] | null;
  gifts: number[] | null;
  personId: number;
}

export function isIBotEvent(entity: any): entity is IBotEvent {
  return "id" in entity && entity.messages && entity.gifts;
}

export interface CreateBotFieldsInterface {
  [CreateBotFieldsEnum.NAME]: string;
  [CreateBotFieldsEnum.SCHEDULE]: IBotSchedule;
  [CreateBotFieldsEnum.FIRST_STREAM]?: number;
  [CreateBotFieldsEnum.DIAMONDS_AMOUNT]?: string;
  [CreateBotFieldsEnum.VIEWERS_AMOUNT]?: string;
  [CreateBotFieldsEnum.GIFTS_AMOUNT]?: string;
  [CreateBotFieldsEnum.EVENTS]?: Array<EventMessage | EventGift>;
}

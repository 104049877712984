import { IBotEvent, IBotSchedule } from ".";

export enum UpdateBotFieldsEnum {
  ID = "id",
  NAME = "name",
  SCHEDULE = "schedule",
  FIRST_STREAM = "firstStream",
  DIAMONDS_AMOUNT = "diamondsAmount",
  VIEWERS_AMOUNT = "viewersAmount",
  GIFTS_AMOUNT = "giftsAmount",
  ACTIVE = "active",
  EVENTS = "events",
}

export interface UpdateBotFieldsInterface {
  [UpdateBotFieldsEnum.ID]: number;
  [UpdateBotFieldsEnum.NAME]?: string | null;
  [UpdateBotFieldsEnum.SCHEDULE]: IBotSchedule;
  [UpdateBotFieldsEnum.FIRST_STREAM]?: number | null;
  [UpdateBotFieldsEnum.DIAMONDS_AMOUNT]?: string | null;
  [UpdateBotFieldsEnum.VIEWERS_AMOUNT]?: string | null;
  [UpdateBotFieldsEnum.GIFTS_AMOUNT]?: string | null;
  [UpdateBotFieldsEnum.ACTIVE]?: boolean;
  [UpdateBotFieldsEnum.EVENTS]?: Array<IBotEvent>;
}

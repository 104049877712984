import styled from "styled-components";

import { MainButton } from "app/components/button/main";

export const SubmitButton = styled(MainButton)`
  margin-top: 10px !important;
  height: 52px !important;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

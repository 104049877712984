import styled from "styled-components";
import { FormInput } from "shards-react";

export const NumberInput = styled(FormInput)<{ ml?: string }>`
  width: 72px;
  height: 46px;
  color: #212121;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: ${({ ml }) => ml || 0}!important;
  border: none;
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
`;

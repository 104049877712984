import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Button, FooterWrapper } from "./styles";

interface FooterProps {
  mainButtonText: string;
  handleSubmit: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  mainButtonText,
  handleSubmit,
}) => {
  return (
    <FooterWrapper>
      <Box
        width="90%"
        display="flex"
        justifyContent="end"
        height="100%"
        alignItems="center"
      >
        <Link to="">
          <Box mr={4}>
            <Button>Отмена</Button>
          </Box>
        </Link>
        <Button bgcolor="#0064FA" color="#FFFFFF" onClick={handleSubmit}>
          {mainButtonText}
        </Button>
      </Box>
    </FooterWrapper>
  );
};

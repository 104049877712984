import { useState } from "react";

import { OrderDirectionEnum } from "app/api/types";
import { FilterFormFieldsInterface } from "app/components/filter/types/filter-form-fields";
import {
  SearchFormFieldsEnum,
  SearchFormFieldsInterface,
} from "app/components/search/types/SearchFormFields";

const useTabContent = () => {
  const [page, setPage] = useState<number>(1);
  const [order, setOrder] = useState<OrNull<string>>(null);
  const [direction, setDirection] = useState<OrNull<OrderDirectionEnum>>(null);
  const [search, setSearch] = useState<OrNull<string>>(null);
  const [filter, setFilter] =
    useState<OrNull<{ field: string; values: FilterFormFieldsInterface }>>(
      null
    );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (order: string, direction: OrderDirectionEnum) => {
    setOrder(order);
    setDirection(direction);
    setPage(1);
  };

  const handleSearch = (values: SearchFormFieldsInterface) => {
    setSearch(values[SearchFormFieldsEnum.SEARCH]);
    setPage(1);
  };

  const handleFilterChange = (
    field: string,
    values: FilterFormFieldsInterface
  ) => {
    setFilter({ field: field, values });
    setPage(1);
  };

  const handleReset = () => {
    setPage(1);
    setOrder(null);
    setDirection(null);
    setSearch(null);
    setFilter(null);
  };

  return {
    page,
    order,
    direction,
    search,
    filter,
    handlers: {
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFilterChange,
      handleReset,
    },
  };
};

export default useTabContent;

import styled from "styled-components";

export const ChangeEventCard = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
`;

export const MessagesBlock = styled.div`
  display: flex;
  white-space: no-wrap;
`;

export const GiftsBlock = styled.div`
  display: flex;
`;

export const Badge = styled.div`
  background: #f5f5f5;
  border-radius: 6px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  margin-right: 18px;
`;

export const EditIcon = styled.img`
  cursor: pointer;
`;

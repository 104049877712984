import React, { useState } from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";

import { Filter } from "app/components/filter";
import { FilterFormFieldsInterface } from "app/components/filter/types/filter-form-fields";
import { TableSortLabelEnum } from "./table-sort-label-enum";
import { Container, StyledButton } from "./styles";

interface TableCellWithSortProps {
  direction: TableSortLabelEnum;
  name: string;
  field: string;
  onSortClick(field: string): void;
  onFilterClick?(field: string, values: FilterFormFieldsInterface): void;
}

export const TableCellWithSort: React.FC<TableCellWithSortProps> = ({
  direction,
  name,
  field,
  onSortClick,
  onFilterClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {onFilterClick && (
        <Filter
          field={field}
          id={id}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          onSubmit={onFilterClick}
        />
      )}
      <TableCell>
        <Container>
          <TableSortLabel
            direction={direction}
            onClick={() => onSortClick(field)}
          >
            {name}
          </TableSortLabel>
          {onFilterClick && (
            <StyledButton size="small" id={id} onClick={handleClick}>
              <FilterList />
            </StyledButton>
          )}
        </Container>
      </TableCell>
    </>
  );
};

import React, { FormEvent, useEffect } from "react";
import { Container } from "@material-ui/core";

import { useLoginForm } from "app/feature/auth/hooks/use-login-form";
import { useAuthMutation } from "app/feature/auth/api";
import { LoginFormFieldsEnum } from "app/feature/auth/types/login-form-fields";
import { Form } from "app/components/form";
import { SubmitForm } from "app/components/submit-form";
import { useAppDispatch } from "app/store-setup/utils";
import { Input } from "app/components/input";
import { StyledTypography, useStyles } from "./styles";
import { setToken } from "app/storage";
import { setIsLogin } from "../../store";

export const LoginForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { values, fields, meta } = useLoginForm();
  const [auth, { data: token, isLoading, error }] = useAuthMutation();
  const handleLoginSubmit = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    auth(values);
  };

  useEffect(() => {
    if (token) {
      setToken(token);
      dispatch(setIsLogin(true));
    }
  }, [token, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <StyledTypography component="h1" variant="h5">
          Sign in
        </StyledTypography>
        <Form className={classes.form} onSubmit={handleLoginSubmit}>
          <Input
            id="username"
            label="Login"
            autoFocus
            {...fields[LoginFormFieldsEnum.USERNAME].field.input}
          />
          <Input
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...fields[LoginFormFieldsEnum.PASSWORD].field.input}
          />
          <SubmitForm
            className={classes.submit}
            error={error}
            isLoading={isLoading}
            disabled={meta.hasValidationErrors}
          >
            Log in
          </SubmitForm>
        </Form>
      </div>
    </Container>
  );
};

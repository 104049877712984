import React from "react";

import { useAppDispatch, useAppSelector } from "app/store-setup/utils";
import { TabWrapper } from "app/components/tab-wrapper";
import useTabContent from "app/hooks/useTabContent";
import { Bot } from "feature/bot/models";
import { useArchiveBotMutation, useGetBotsQuery } from "../api";
import { setBot } from "../store";
import { BotList } from "app/components/bot-list";
import { selectActiveTab } from "app/store";

export const BotTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectActiveTab);
  const { page, order, direction, search, filter, handlers } = useTabContent();
  const [deleteBot, { isLoading, error }] = useArchiveBotMutation();

  const { data: botList } = useGetBotsQuery({
    page,
    order,
    direction,
    search,
    filter,
  });

  const handleDeleteClick = (bot: Bot) => {
    deleteBot(bot.id);
  };

  const handleEditClick = (bot: Bot) => {
    dispatch(setBot(bot));
  };

  return (
    <>
      {botList && (
        <TabWrapper
          list={botList}
          page={page}
          onPageChange={handlers.handlePageChange}
          onSearchSubmit={handlers.handleSearch}
        >
          <BotList
            list={botList}
            onSortChange={handlers.handleSortChange}
            onFilterChange={handlers.handleFilterChange}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
          />
        </TabWrapper>
      )}
    </>
  );
};

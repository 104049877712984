import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

import { ApiErrorTypeEnum, ApiErrorMessageEnum } from "app/api/types";
import { setIsLogin } from "app/feature/auth/store";
import { resetToken } from "app/storage";
import { AppMiddlewareAPI } from "./types";

export const errorHandlerMiddleware: Middleware =
  (api: AppMiddlewareAPI) => (next) => (action) => {
    if (!isRejectedWithValue(action)) {
      return next(action);
    }

    const errorData = action.payload.data;
    const errorType = getErrorTypeByStatusCode(action.payload.status);

    if (
      errorType === ApiErrorTypeEnum.UNAUTHORIZED ||
      (errorType === ApiErrorTypeEnum.BAD_REQUEST &&
        errorData.message === ApiErrorMessageEnum.AUTHENTICATION_FAILURE)
    ) {
      api.dispatch(setIsLogin(false));
      resetToken();
    }

    if (errorType === ApiErrorTypeEnum.BAD_REQUEST) {
      return next({
        ...action,
        payload: {
          message: errorData.messageText,
        },
      });
    }

    return next({
      ...action,
      payload: {
        type: errorType,
      },
    });
  };

function getErrorTypeByStatusCode(statusCode: number) {
  switch (statusCode) {
    case 400:
      return ApiErrorTypeEnum.BAD_REQUEST;
    case 401:
      return ApiErrorTypeEnum.UNAUTHORIZED;
    case 403:
      return ApiErrorTypeEnum.FORBIDDEN;
    case 404:
      return ApiErrorTypeEnum.NOT_FOUND;
  }

  return ApiErrorTypeEnum.UNEXPECTED_ERROR;
}

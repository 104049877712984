import React, { ChangeEvent } from "react";
import { Box } from "@material-ui/core";
import { Icon, StyledInput } from "../../styles";
import { SmileString } from "app/components/add-bot/components/smile-string";
import minus from "app/asset/icons/minus.svg";

interface MessageInputsProps {
  messages: { [key: string]: string };
  handleDeleteInput: (inputId: string) => void;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleAddSmile: (messages: { [key: string]: string }) => void;
}

export const MessageInputs: React.FC<MessageInputsProps> = ({
  messages,
  handleDeleteInput,
  handleChangeInput,
  handleAddSmile,
}) => {
  return (
    <Box>
      {Object.keys(messages).map((key, index) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
          key={key}
        >
          <StyledInput
            value={messages[key]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e, key)
            }
            id="message"
            placeholder="Введите сообщение"
          />
          {index !== 0 && (
            <Icon onClick={() => handleDeleteInput(key)} src={minus} />
          )}
          <SmileString
            border="none"
            handleChange={(char: string) =>
              handleAddSmile({ ...messages, [key]: messages[key] + char })
            }
          />
        </Box>
      ))}
    </Box>
  );
};

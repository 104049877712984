import styled from "styled-components";

export const TextDescription = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #a4a4a4;
`;

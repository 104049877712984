import { Box } from "@material-ui/core";
import React from "react";
import { TextEditorTextOption } from "../card-event/styles";

interface SmileStringInterface {
  border?: string;
  handleChange: (elem: string) => void;
}
export const SmileString: React.FC<SmileStringInterface> = ({
  border,
  handleChange,
}) => {
  const smiles = ["😂", "❤️", "😍", "😒", "👌", "😊", "💕", "😩", "😭", "😘"];
  return (
    <Box
      border={border ? border : "1"}
      borderColor="#D2D2D2"
      display="flex"
      width="101%"
      alignItems="baseline"
    >
      {smiles.map((smile, index) => (
        <TextEditorTextOption onClick={() => handleChange(smile)} key={index}>
          {smile}
        </TextEditorTextOption>
      ))}
    </Box>
  );
};

import React, { useState } from "react";
import { Table, TableCell, TableRow } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { TableSortLabelEnum } from "../table/tableCellWithSort/table-sort-label-enum";
import { rows } from "./rows";

import { Container } from "./styles";
import { Bot } from "feature/bot/models";
import { TableProps } from "app/api/types/table-props";
import { OrderDirectionEnum } from "app/api/types";
import { FilterFormFieldsInterface } from "../filter/types/filter-form-fields";
import { AppTableHead } from "../table/table-head";
import { AppTableBody } from "../table/tableBody";
import { TableCellWithSort } from "../table/tableCellWithSort";
import { StatusCell } from "./components/status-cell";
import { RangeCell } from "./components/range-cell";
import { OptionsCell } from "./components/options-cell";
import { useUpdateBotMutation } from "feature/bot/api";

export const BotList: React.FC<TableProps<Bot>> = ({
  onDeleteClick,
  onEditClick,
  onSortChange,
  onFilterChange,
  list,
}) => {
  const [isAsc, setIsAsc] = useState<boolean>(true);
  const [updateBot, { isLoading, error }] = useUpdateBotMutation();

  const handleSortCLick = (order: string) => {
    setIsAsc(!isAsc);

    const direction = isAsc ? OrderDirectionEnum.ASC : OrderDirectionEnum.DESC;

    if (onSortChange) onSortChange(order, direction);
  };

  const handleFilterCLick = (
    field: string,
    values: FilterFormFieldsInterface
  ) => {
    if (onFilterChange) onFilterChange(field, values);
  };

  const handleDeleteClick = (botId: number) => {
    const bot = list?.list.find((bot) => bot.id === botId);

    if (bot && onDeleteClick) {
      onDeleteClick(bot);
    }
  };

  const handleEditClick = (botId: number) => {
    const bot = list?.list.find((bot) => bot.id === botId);

    if (bot && onEditClick) {
      onEditClick(bot);
    }
  };

  const handleStatusClick = (botId: number) => {
    const bot = list?.list.find((bot) => bot.id === botId);

    if (bot) {
      updateBot({ ...bot, active: !bot.active });
    }
  };

  return (
    <Container>
      <TableContainer>
        <Table aria-label="simple table">
          <AppTableHead>
            <TableRow>
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name=""
                field="status"
                onSortClick={handleSortCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Название бота"
                field="name"
                onSortClick={handleSortCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Количество событий"
                field="eventsCount"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Первый стрим"
                field="firstStream"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Количество бриллиантов"
                field="diamondsAmount"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Количество зрителей"
                field="viewersAmount"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Количество подаренных подарков"
                field="giftsAmount"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCell />
            </TableRow>
          </AppTableHead>
          <AppTableBody>
            {list?.list &&
              rows(list.list).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <StatusCell
                      value={row.active}
                      handleClick={() => handleStatusClick(row.id)}
                    />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.events?.length}</TableCell>
                  <TableCell>{row.firstStream}</TableCell>
                  <TableCell>
                    {<RangeCell value={row.diamondsAmount} />}
                  </TableCell>
                  <TableCell>
                    {<RangeCell value={row.viewersAmount} />}
                  </TableCell>
                  <TableCell>{<RangeCell value={row.giftsAmount} />}</TableCell>
                  <TableCell>
                    <OptionsCell
                      onDeleteClick={handleDeleteClick}
                      onEditClick={handleEditClick}
                      botId={row.id}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </AppTableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

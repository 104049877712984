import { api } from "app/api";
import { ListResponse } from "app/api/types/entity-list-response";
import { urlGenerator } from "app/utils/urlGenerator";
import { Gift } from "feature/gift/models";

const giftsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGifts: build.query<ListResponse<Gift>, void>({
      query: () => {
        return {
          url: urlGenerator(`/gifts`),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.list.map(({ id }) => ({ type: "Gift", id } as const)),
              "Gift",
            ]
          : ["Gift"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetGiftsQuery } = giftsApi;

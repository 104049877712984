export enum CreatePersonFieldsEnum {
  COINS = "coins",
  DIAMONDS = "diamonds",
  NAME = "name",
  EMAIL = "email",
  AVATAR_URL = "avatarUrl",
}

export interface CreatePersonFieldsInterface {
  [CreatePersonFieldsEnum.COINS]: number;
  [CreatePersonFieldsEnum.DIAMONDS]: number;
  [CreatePersonFieldsEnum.NAME]: string;
  [CreatePersonFieldsEnum.EMAIL]: string;
  [CreatePersonFieldsEnum.AVATAR_URL]: string | undefined;
}

import styled from "styled-components";
import { Box } from "@material-ui/core";

export const TimePickerWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: blue;
  width: 225px;
  height: 316px;
  background: #0064fa;
  border-radius: 0px 8px 8px 0px;
`;

export const StyledTimePicker = styled(Box)`
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.28);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px;
  input {
    color: white !important;
  }
  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: white !important;
  }
  svg {
    color: white !important;
  }
`;

export const config = {
  env: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV === "development",
  authBaseUrl: process.env.REACT_APP_BASE_AUTH_URL,
  apiBaseUrl: process.env.REACT_APP_BASE_API_URL,
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  basename: process.env.REACT_APP_BASE_NAME,
  localStorageKey: {
    authToken: "auth-token",
  },
};

import { Person } from "feature/person/models";

const createData = (
  id: number,
  avatarUrl: string,
  name: string,
  email: string,
  coins: number,
  diamonds: number
) => {
  return {
    id,
    avatarUrl,
    name,
    email,
    coins,
    diamonds,
  };
};

export const rows = (personList: Person[]) => {
  return personList?.map((person) =>
    createData(
      person.id,
      person.avatarUrl,
      person.name,
      person.email,
      person.coins,
      person.diamonds
    )
  );
};

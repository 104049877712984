import { GetParamsInterface } from "../api/types/get-params-Interface";

export const urlGenerator = (url: string, getParams?: GetParamsInterface) => {
  let formattedUrl = url;

  if (getParams) {
    let params = Object.entries(getParams)
      .map(([paramKey, paramValue]) => `${paramKey}=${paramValue}`)
      .join("&");

    return formattedUrl + "?" + params;
  }

  return formattedUrl;
};

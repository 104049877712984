import React, { FormEvent } from "react";
import { Button, Popover, TextField } from "@material-ui/core";

import { useFilterForm } from "./hooks/use-filter-form";
import {
  FilterFormFieldsInterface,
  FilterFormFieldsEnum,
} from "./types/filter-form-fields";
import { Form } from "./styles";

interface FilterProps {
  id: string | undefined;
  onClose(): void;
  open: boolean;
  anchorEl: Element | null;
  field: string;
  onSubmit(field: string, values: FilterFormFieldsInterface): void;
}

export const Filter: React.FC<FilterProps> = ({
  id,
  onClose,
  open,
  anchorEl,
  field,
  onSubmit,
}) => {
  const { meta, values, fields } = useFilterForm();

  const handleSubmit = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    onSubmit(field, values);
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          id="outlined-size-small"
          size="small"
          label="From"
          variant="outlined"
          type="number"
          onKeyDown={(evt) =>
            (evt.key === "e" || evt.key === "E") && evt.preventDefault()
          }
          {...fields[FilterFormFieldsEnum.FROM].field.input}
        />
        <TextField
          id="outlined-size-small"
          size="small"
          label="To"
          variant="outlined"
          type="number"
          {...fields[FilterFormFieldsEnum.TO].field.input}
        />
        <Button
          disabled={meta.hasValidationErrors}
          type="submit"
          variant="contained"
        >
          Ok
        </Button>
      </Form>
    </Popover>
  );
};

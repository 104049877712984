import useForm from "app/hooks/useForm";
import {
  SearchFormFieldsEnum,
  SearchFormFieldsInterface,
} from "../types/SearchFormFields";

export const useSearchForm = (initialValues?: SearchFormFieldsInterface) => {
  const { values, useField, form } =
    useForm<SearchFormFieldsInterface>(initialValues);

  return {
    form,
    values,
    fields: {
      [SearchFormFieldsEnum.SEARCH]: useField(SearchFormFieldsEnum.SEARCH),
    },
  };
};

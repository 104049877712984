import { Box } from "@material-ui/core";
import React from "react";
import { ChangeEventCard, EditIcon } from "./styles";
import editIcon from "app/asset/icons/table/editIcon.svg";
import dragIcon from "app/asset/icons/dragIcon.svg";
import { Gifts } from "./components/gifts";
import { Messages } from "./components/messages";
import { PersonRow } from "app/components/person-row";
import { EventTypeEnum } from "feature/bot/api/types";

interface CardChangeEventProps {
  messages?: string[];
  gifts?: number[];
  personIds: number[];
  delay: number;
  type: EventTypeEnum;
  handleClickEdit: () => void;
}

export const CardChangeEvent: React.FC<CardChangeEventProps> = ({
  messages,
  gifts,
  personIds,
  type,
  delay,
  handleClickEdit,
}) => {
  return (
    <ChangeEventCard>
      <Box height="100%" pl={2}>
        <img src={dragIcon} width={10} height={12} />
      </Box>
      <Box display="flex" flexDirection="column" ml={4}>
        <Box display="flex" justifyContent="space-between" my={2}>
          {type === EventTypeEnum.Message && messages && (
            <Messages messages={messages} />
          )}
          {type === EventTypeEnum.Event && gifts && <Gifts gifts={gifts} />}
        </Box>
        <PersonRow personIds={personIds} />
      </Box>
      <Box p={2}>
        <EditIcon src={editIcon} onClick={handleClickEdit} />
      </Box>
    </ChangeEventCard>
  );
};

import React from "react";

import { StyledTextField } from "./styles";

interface InputProps {
  id: string;
  label: string;
  autoFocus?: boolean;
  type?: string;
  autoComplete?: string;
  required?: boolean;
}

export const Input: React.FC<InputProps> = ({
  id,
  label,
  autoFocus,
  type,
  autoComplete,
  required = true,
  ...props
}) => (
  <StyledTextField
    variant="outlined"
    margin="normal"
    required={required}
    fullWidth
    label={label}
    id={id}
    autoFocus={autoFocus}
    type={type}
    autoComplete={autoComplete}
    {...props}
  />
);

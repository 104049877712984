import styled from "styled-components";
import { Input } from "@material-ui/core";
import { GridSearchIcon } from "@material-ui/data-grid";

export const Container = styled.form`
  display: flex;
`;

export const StyledInput = styled(Input)`
  margin-right: 20px;
`;

export const StyledIcon = styled(GridSearchIcon)`
  fill: ${({ theme }) => theme.colors.c5} !important;
`;

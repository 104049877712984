import AppTypes from "AppTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: false,
  reducers: {
    setIsLogin: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { setIsLogin } = authSlice.actions;

export const selectIsLogin = (state: AppTypes.Redux.AppRootState) => state.auth;

export const authReducer = authSlice.reducer;

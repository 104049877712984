import styled from "styled-components";
import { Box } from "@material-ui/core";

export const GiftBox = styled(Box)<{ isActive: boolean }>`
  cursor: pointer;
  background: ${({ isActive }) =>
    isActive ? "rgba(189, 214, 253, 0.68)" : "white"};
  &:hover {
    background: rgba(189, 214, 253, 0.12);
  }
`;

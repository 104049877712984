import { Switch } from "@material-ui/core";
import React from "react";

interface StatusCellProps {
  value: boolean;
  handleClick: () => void;
}
export const StatusCell: React.FC<StatusCellProps> = ({
  value,
  handleClick,
}) => {
  return <Switch checked={value} onClick={handleClick} />;
};

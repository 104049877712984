import React, { FormEvent, useEffect } from "react";
import { InputAdornment } from "@material-ui/core";

import { MainButton } from "../button/main";
import { useSearchForm } from "./hooks/useSearchForm";
import {
  SearchFormFieldsEnum,
  SearchFormFieldsInterface,
} from "./types/SearchFormFields";
import { Container, StyledIcon, StyledInput } from "./styles";

interface SearchProps {
  onSubmit(values: SearchFormFieldsInterface): void;
  isReset: boolean;
  onChange(): void;
}

export const Search: React.FC<SearchProps> = ({
  isReset,
  onSubmit,
  onChange,
}) => {
  const { values, fields, form } = useSearchForm();

  const handleSubmit = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    onSubmit(values);
  };

  useEffect(() => {
    if (isReset) {
      form.reset();
    }
  }, [isReset, form]);

  return (
    <Container onSubmit={handleSubmit}>
      <StyledInput
        id="input-with-icon-adornment"
        onInput={onChange}
        {...fields[SearchFormFieldsEnum.SEARCH].field.input}
        startAdornment={
          <InputAdornment position="start">
            <StyledIcon />
          </InputAdornment>
        }
      />

      <MainButton>Search</MainButton>
    </Container>
  );
};

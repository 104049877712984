import styled from "styled-components";
import { TableBody } from "@material-ui/core";

export const StyledTableBody = styled(TableBody)`
  td,
  th {
    font-family: ${({ theme }) => theme.fontFamily.sfProDisplay} !important;
    color: ${({ theme }) => theme.colors.c6} !important;
  }
`;

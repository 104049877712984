import styled from "styled-components";
import { FormInput, FormSelect } from "shards-react";

export const Input = styled(FormInput)<{
  width: string;
  height: string;
  background: string;
}>`
  width: ${({ width }) => (width ? width : "312px")};
  height: ${({ height }) => (height ? height : "46px")};
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 10px 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: black;
  padding: 5px 17px;
  background: ${({ background }) => (background ? background : "white")};
`;

export const Button = styled.div`
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 10px;
  color: #212121;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 46px;
  background: #f5f5f5;
  border-radius: 8px;
`;

export const Label = styled.label`
  color: #bdbdbd;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

export const StyledFormSelect = styled(FormSelect)`
  width: 128px;
  height: 32px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 5px;
  margin-bottom: 10px;
`;

import React from "react";
import { CircularProgress } from "@material-ui/core";

import { Error } from "../error";
import { SubmitButton, Container } from "./styles";

interface SubmitFormProps {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  error?: any;
}

export const SubmitForm: React.FC<SubmitFormProps> = ({
  disabled,
  isLoading,
  error,
  className,
  children,
}) => (
  <Container>
    <SubmitButton className={className} disabled={disabled}>
      {isLoading ? <CircularProgress /> : children}
    </SubmitButton>
    <Error>{error?.message}</Error>
  </Container>
);

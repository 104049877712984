import { api } from "app/api";
import { EntityListRequest, EntityListResponse } from "app/api/types";
import { urlGenerator } from "app/utils/urlGenerator";
import { getParams } from "app/utils/getParams";
import { Person } from "feature/person/models";
import {
  CreatePersonRequestInterface,
  UpdatePersonRequestInterface,
} from "./types";

const personsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPersons: build.query<EntityListResponse<Person>, EntityListRequest>({
      query: (request) => {
        const params = getParams(request);

        return {
          url: urlGenerator(`/users/bot-persons/${request.page}`, params),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.list.map(({ id }) => ({ type: "Person", id } as const)),
              "Person",
            ]
          : ["Person"],
    }),
    archivePerson: build.mutation<void, number>({
      query: (id) => ({
        url: `/users/bot-person/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Person"],
    }),
    createPerson: build.mutation<void, CreatePersonRequestInterface>({
      query: (createPerson: CreatePersonRequestInterface) => ({
        url: `/users/bot-person`,
        body: createPerson,
        method: "post",
      }),
      invalidatesTags: ["Person"],
    }),
    updatePerson: build.mutation<void, UpdatePersonRequestInterface>({
      query: (updatePerson: UpdatePersonRequestInterface) => ({
        url: `/users/bot-person`,
        body: updatePerson,
        method: "put",
      }),
      invalidatesTags: ["Person"],
    }),
    uploadAvatar: build.mutation<string, any>({
      query: (body: any) => ({
        url: `/users/upload/avatar`,
        body,
        method: "post",
      }),
      invalidatesTags: ["Person"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPersonsQuery,
  useArchivePersonMutation,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useUploadAvatarMutation,
} = personsApi;

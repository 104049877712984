import { EntityListRequest } from "../api/types";
import { GetParamsInterface } from "../api/types/get-params-Interface";

export const getParams = (request: EntityListRequest): GetParamsInterface => {
  let params = {};

  if (request.order) {
    params = {
      orderBy: request.order,
      direction: request.direction,
    };
  }

  if (request.search) {
    params = {
      ...params,
      search: request.search,
    };
  }

  if (request.filter) {
    params = {
      ...params,
      filterBy: request.filter.field,
      from: request.filter.values.from,
      to: request.filter.values.to,
    };
  }

  return params;
};

import { Box } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { Audience } from "app/components/add-bot/components/audience";
import { CardEvent } from "app/components/add-bot/components/card-event";
import { Events, MainButton, NameInput } from "app/components/add-bot/styles";
import addIcon from "app/asset/icons/addIcon.svg";
import { EventModal } from "app/components/event-modal";
import { EventMessage, EventGift } from "app/components/event-modal/types";
import { Footer } from "app/components/footer";
import { WorkSchedule } from "app/components/work-schedule";
import { ScheduleDatePicker } from "app/components/schedule-date-picker";
import { useCreateBotMutation } from "feature/bot/api";
import { CreateBotFieldsEnum, EventTypeEnum } from "feature/bot/api/types";
import { useHistory } from "react-router-dom";
import moment from "moment";

export const AddBot: React.FC = () => {
  const [everyDay, setEveryDay] = useState(false);
  const [events, setEvents] = useState<Array<EventMessage | EventGift>>([
    {
      delay: 0,
      type: EventTypeEnum.Message,
    },
  ]);
  const [name, setName] = useState<string>("");
  const [firstStream, setFirstStream] = useState<number | null>(null);
  const [diamondsAmount, setDiamondsAmount] = useState<string | null>(null);
  const [viewersAmount, setViewersAmount] = useState<string | null>(null);
  const [giftsAmount, setGiftsAmount] = useState<string | null>(null);
  const [isEventModalOpen, setIsOpenModalOpen] = useState(false);
  const history = useHistory();
  const [isAllDay, setAllDay] = useState(false);
  const [variability, setVariability] = useState(false);

  const [createBot, { isLoading, error }] = useCreateBotMutation();

  const handleChangeWorkSchedule = () => {
    setEveryDay((prevVal) => !prevVal);
  };

  const handleChange =
    (setState: React.Dispatch<React.SetStateAction<string | null>>) =>
    (value: string | null) => {
      setState(value);
    };

  const handleChangeFirstStream = (value: number | null) => {
    setFirstStream(value);
  };

  const handleEventModalClose = () => {
    setIsOpenModalOpen(false);
  };

  console.log({ events });
  const handleSubmit = () => {
    const request = {
      [CreateBotFieldsEnum.NAME]: name,
      [CreateBotFieldsEnum.SCHEDULE]: {
        from: isAllDay ? moment().utc().startOf("day").toISOString() : "",
        to: isAllDay ? moment().utc().endOf("day").toISOString() : "",
        isAllDay: isAllDay,
        isEveryDay: everyDay,
      },
      [CreateBotFieldsEnum.FIRST_STREAM]: firstStream ? firstStream : undefined,

      [CreateBotFieldsEnum.DIAMONDS_AMOUNT]: diamondsAmount
        ? diamondsAmount
        : undefined,

      [CreateBotFieldsEnum.VIEWERS_AMOUNT]: viewersAmount
        ? viewersAmount
        : undefined,

      [CreateBotFieldsEnum.GIFTS_AMOUNT]: giftsAmount ? giftsAmount : undefined,
      [CreateBotFieldsEnum.EVENTS]: events.map((event) => ({
        ...event,
        type: event.type,
        variability,
        personId: event?.personId || 1,
      })),
    };

    createBot(request).then(() => {
      history.push("/");
    });
  };

  const handleSwitchAllDay = () => {
    setAllDay((prevValue) => !prevValue);
  };

  const handleChangeVariability = () => {
    setVariability((prevValue) => !prevValue);
  };

  return (
    <>
      <EventModal
        isOpen={isEventModalOpen}
        onClose={handleEventModalClose}
        setEvents={setEvents}
        mainButtonText="Добавить"
      />
      <Events>
        <Box width="593px">
          <NameInput
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            placeholder="Название"
          />
          <WorkSchedule
            isEveryDay={everyDay}
            onChange={handleChangeWorkSchedule}
          />
          {!everyDay ? (
            <ScheduleDatePicker
              isAllDay={isAllDay}
              handleSwitchAllDay={handleSwitchAllDay}
            />
          ) : (
            ""
          )}
          <Audience
            setDiamondsAmount={handleChange(setDiamondsAmount)}
            setViewersAmount={handleChange(setViewersAmount)}
            setGiftsAmount={handleChange(setGiftsAmount)}
            setFirstStream={handleChangeFirstStream}
            diamondsAmount={diamondsAmount}
            viewersAmount={viewersAmount}
            giftsAmount={giftsAmount}
            firstStream={firstStream}
          />
        </Box>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={4}
        >
          <h1>События</h1>
          {events.map((event, index) => (
            <CardEvent
              counter={index + 1}
              event={event}
              setEvents={setEvents}
              events={events}
              handleChangeVariability={handleChangeVariability}
              variability={variability}
            />
          ))}
          <MainButton onClick={() => setIsOpenModalOpen(true)}>
            <Box display="flex" alignItems="center" justifyContent="center">
              {<img src={addIcon} />} <Box ml={2}>Добавить событие</Box>
            </Box>
          </MainButton>
        </Box>
      </Events>
      <Footer mainButtonText="Создать" handleSubmit={handleSubmit} />
    </>
  );
};

import { EventTypeEnum } from "feature/bot/api/types";

type Event = {
  delay: number;
  personId?: number;
  type: EventTypeEnum;
  variability?: boolean;
};

export interface EventMessage extends Event {
  messages?: string[];
}

export interface EventGift extends Event {
  gifts?: number[];
}

export function isGiftEvent(event: Event): event is EventGift {
  return "gifts" in event && event.type === EventTypeEnum.Event;
}

export function isMessagesEvent(event: Event): event is EventMessage {
  return "messages" in event && event.type === EventTypeEnum.Message;
}

import { IBotEvent } from "feature/bot/api/types";
import { Bot } from "feature/bot/models";

const createData = (
  id: number,
  active: boolean,
  name: string,
  events: IBotEvent[],
  firstStream: number,
  diamondsAmount: string,
  viewersAmount: string,
  giftsAmount: string
) => {
  return {
    id,
    active,
    name,
    events,
    firstStream,
    diamondsAmount,
    viewersAmount,
    giftsAmount,
  };
};

export const rows = (botList: Bot[]) => {
  return botList?.map((bot) =>
    createData(
      bot.id,
      bot.active,
      bot.name,
      bot.events,
      bot.firstStream,
      bot.diamondsAmount,
      bot.viewersAmount,
      bot.giftsAmount
    )
  );
};

import React, { useEffect } from "react";

import { useAppDispatch } from "app/store-setup/utils";
import { TabWrapper } from "app/components/tab-wrapper";
import useTabContent from "app/hooks/useTabContent";
import { Person } from "../models";
import { PersonList } from "app/components/person-list";
import { setPerson, setPersons } from "../store";
import { useGetPersonsQuery } from "../api";

export const PersonTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { page, order, direction, search, filter, handlers } = useTabContent();

  const { data: personList } = useGetPersonsQuery({
    page,
    order,
    direction,
    search,
    filter,
  });

  const handleDeleteClick = (person: Person) => {
    dispatch(setPerson(person));
  };

  useEffect(() => {
    if (personList) {
      dispatch(setPersons(personList.list));
    }
  }, [personList]);

  return (
    <>
      {personList && (
        <TabWrapper
          list={personList}
          page={page}
          onPageChange={handlers.handlePageChange}
          onSearchSubmit={handlers.handleSearch}
        >
          <PersonList
            list={personList}
            onSortChange={handlers.handleSortChange}
            onFilterChange={handlers.handleFilterChange}
            onDeleteClick={handleDeleteClick}
          />
        </TabWrapper>
      )}
    </>
  );
};

import { Box, FormControl, FormLabel, Checkbox } from "@material-ui/core";
import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Counter,
  DragIcon,
  RegularText,
  TimePeekCard,
  EventCard,
  StyledFormRadio,
  AddOption,
  BoldText,
} from "./styles";

import { NumberInput } from "app/components/styles";

import dragIcon from "app/asset/icons/dragIcon.svg";
import pauseIcon from "app/asset/icons/pauseIcon.svg";
import { MessageEvent } from "../message-event";
import { GiftEvent } from "../gift-event";
import {
  EventGift,
  EventMessage,
  isGiftEvent,
  isMessagesEvent,
} from "app/components/event-modal/types";
import { EventTypeEnum } from "feature/bot/api/types";
import moment from "moment";
import { StyledSelect } from "app/components/event-modal/styles";
import { Person } from "feature/person/models";
import { useAppSelector } from "app/store-setup/utils";
import { Persons } from "feature/person/store/types";

interface CardEventProps {
  counter: number;
  event: EventMessage | EventGift;
  setEvents: React.Dispatch<
    React.SetStateAction<Array<EventMessage | EventGift>>
  >;
  events: Array<EventMessage | EventGift>;
  delay?: number;
  variability: boolean;
  handleChangeVariability: () => void;
}

export const CardEvent: React.FC<CardEventProps> = ({
  counter,
  setEvents,
  events,
  delay,
  variability,
  handleChangeVariability,
  event,
}) => {
  const persons: Persons = useAppSelector((store) => store.person.persons);
  const [messages, setMessages] = useState<string[]>(
    (isMessagesEvent(event) && event.messages) || [""]
  );

  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(
    event && event.personId ? event.personId : null
  );

  const [eventType, setEventType] = useState(
    event.type || EventTypeEnum.Message
  );
  const [selectedGifts, setSelectedGifts] = useState<number[]>(
    (isGiftEvent(event) && event.gifts) || []
  );
  const [delayMinutes, setDelayMinutes] = useState(
    delay
      ? Math.floor(moment.duration(event.delay || delay).asSeconds() / 60)
      : 0
  );
  const [delaySeconds, setDelaySeconds] = useState(
    delay ? moment.duration(event.delay || delay).asSeconds() % 60 : 0
  );

  useEffect(() => {
    if (messages.length) {
      setEvents((events: any) =>
        events.map((event: EventMessage | EventGift, index: number) =>
          index === counter - 1
            ? {
                ...event,
                messages,
                personId: selectedPersonId
                  ? Number(selectedPersonId)
                  : Object.values(persons)[0].id,
              }
            : event
        )
      );
    }
    if (selectedGifts.length) {
      setEvents((events: any) =>
        events.map((event: EventMessage | EventGift, index: number) =>
          index === counter - 1
            ? {
                ...event,
                gifts: selectedGifts,
                personId: selectedPersonId
                  ? Number(selectedPersonId)
                  : Object.values(persons)[0].id,
              }
            : event
        )
      );
    }

    if (delayMinutes || delaySeconds) {
      setEvents((events: any) =>
        events.map((event: EventMessage | EventGift, index: number) =>
          index === counter - 1
            ? {
                ...event,
                delay: delayMinutes * 60000 + delaySeconds * 1000,
              }
            : event
        )
      );
    }
  }, [
    delayMinutes,
    delaySeconds,
    selectedGifts,
    eventType,
    messages,
    selectedGifts,
    selectedPersonId,
  ]);

  const handleClickAddMessage = () => {
    setMessages([...messages, ""]);
  };

  const handleChangeMessage = (
    e: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    setMessages((prevValues) =>
      prevValues.map((message: string, ind: number) => {
        return ind === index ? e.target.value : message;
      })
    );
    e.preventDefault();
    e.stopPropagation();
  };

  const handleAddSmile = (messages: string[]) => {
    setMessages(messages);
  };

  return (
    <Box p={4} display="flex" flexDirection="column" width="647px">
      <EventCard>
        <Box display="flex" flexDirection="column" mr={3}>
          <Counter>{counter}</Counter>
          <DragIcon>
            <img src={dragIcon} />
          </DragIcon>
        </Box>
        <Box display="flex" flexDirection="column">
          <FormControl>
            <FormLabel id="">Тип</FormLabel>
            <Box display="flex" flexDirection="row" py={1} alignItems="end">
              <StyledFormRadio
                name="messages"
                checked={eventType === EventTypeEnum.Message}
                onChange={() => {
                  setEventType(EventTypeEnum.Message);
                }}
              >
                Сообщения
              </StyledFormRadio>
              <Box ml={2}>
                <StyledFormRadio
                  name="gifts"
                  checked={eventType === EventTypeEnum.Event}
                  onChange={() => {
                    setEventType(EventTypeEnum.Event);
                  }}
                >
                  Подарки
                </StyledFormRadio>
              </Box>
            </Box>
          </FormControl>
          {eventType === EventTypeEnum.Message ? (
            <MessageEvent
              handleChangeMessage={handleChangeMessage}
              messages={messages}
              handleAddSmile={handleAddSmile}
            />
          ) : (
            <GiftEvent
              setSelectedGifts={setSelectedGifts}
              selectedGifts={selectedGifts}
            />
          )}
          <Box display="flex" flexDirection="column">
            {eventType === EventTypeEnum.Message && (
              <AddOption onClick={handleClickAddMessage}>
                + Добавить сообщение
              </AddOption>
            )}
            <BoldText>Личность</BoldText>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              my={2}
            >
              <StyledSelect
                placeholder="Выберите личность"
                value={selectedPersonId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedPersonId(Number(e?.target?.value));
                }}
              >
                {Object.values(persons).map((person: Person) => (
                  <option value={person.id}>{person.name}</option>
                ))}
              </StyledSelect>
            </Box>
            <AddOption>+ Добавить личность</AddOption>
          </Box>
        </Box>
      </EventCard>
      <Box mt={4} width="671px" display="flex" alignItems="baseline">
        <TimePeekCard>
          <Box mr={2}>
            <img src={pauseIcon} width="18" height="18" />
          </Box>
          <NumberInput
            id="minutes"
            type="number"
            defaultValue="00"
            label="Минуты"
            value={delayMinutes}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setDelayMinutes(Number(e.target.value))
            }
          />
          <NumberInput
            id="seconds"
            type="number"
            label="Секунды"
            defaultValue="00"
            ml="40px"
            value={delaySeconds}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setDelaySeconds(Number(e.target.value))
            }
          />
          <Box ml={4} display="flex" alignItems="center">
            <Checkbox
              checked={variability}
              onChange={handleChangeVariability}
            />
            <RegularText> Вариативность </RegularText>
          </Box>
        </TimePeekCard>
      </Box>
    </Box>
  );
};

import styled from "styled-components";

export const Name = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
  display: flex;
  white-space: nowrap;
  margin-left: 12px;
`;

export const Link = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0064fa;
  cursor: pointer;
`;

export const Count = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
  margin-left: 12px;
`;

export const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #b4b4b4;
`;

import useForm from "app/hooks/useForm";
import { isInt } from "app/utils/validator";
import {
  FilterFormFieldsEnum,
  FilterFormFieldsInterface,
} from "../types/filter-form-fields";

const intValidator = (value: string | undefined) => isInt(value);

export const useFilterForm = (initialValues?: FilterFormFieldsInterface) => {
  const { meta, values, useField } =
    useForm<FilterFormFieldsInterface>(initialValues);

  return {
    values,
    meta,
    fields: {
      [FilterFormFieldsEnum.FROM]: useField(
        FilterFormFieldsEnum.FROM,
        intValidator
      ),
      [FilterFormFieldsEnum.TO]: useField(
        FilterFormFieldsEnum.TO,
        intValidator
      ),
    },
  };
};

import styled from "styled-components";
import { Pagination } from "@material-ui/lab";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const Tools = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledPagination = styled(Pagination)`
  .Mui-selected,
  .MuiPaginationItem-page:hover {
    background-color: ${({ theme }) => theme.colors.c4} !important;
  }
  .MuiPaginationItem-root {
    font-family: ${({ theme }) => theme.fontFamily.sfProDisplay} !important;
  }
`;

export const TableSwitchButton = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? "#0064FA;" : "#f9f9f9")};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px 22px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "#FFFFFF" : "#212121")};
`;

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  margin-left: 32px;
`;

import { Box } from "@material-ui/core";
import React from "react";
import { useGetGiftsQuery } from "feature/gift/api";
import { Gift } from "feature/gift/models";
import { GiftBox } from "./styles";

interface GiftEventProps {
  selectedGifts: number[];
  setSelectedGifts: React.Dispatch<React.SetStateAction<number[]>>;
}
export const GiftEvent: React.FC<GiftEventProps> = ({
  selectedGifts,
  setSelectedGifts,
}) => {
  const { data: giftList } = useGetGiftsQuery();

  const handleToogleGift = (gift: Gift) => {
    if (selectedGifts.includes(gift.id)) {
      setSelectedGifts((prevValues: number[]) =>
        prevValues.filter((item) => item !== gift.id)
      );
    } else {
      setSelectedGifts((prevValues) => [...prevValues, gift.id]);
    }
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {giftList?.list.map((gift: Gift) => (
          <GiftBox
            onClick={() => handleToogleGift(gift)}
            isActive={Boolean(selectedGifts.includes(gift.id))}
            key={gift.id}
            mr={2}
            p={1}
          >
            <img src={gift.image} width={45} height={45} />
          </GiftBox>
        ))}
      </Box>
    </Box>
  );
};

import React from "react";

import { StyledButton } from "./styles";

interface ButtonProps {
  onClick?(): void;
  component?: string;
  className?: string;
  disabled?: boolean;
}

export const MainButton: React.FC<ButtonProps> = ({
  component,
  onClick,
  disabled,
  className,
  children,
}) => {
  return (
    <StyledButton
      type="submit"
      variant="contained"
      onClick={onClick}
      component={component}
      className={className}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

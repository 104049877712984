import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(226, 230, 229, 0.598995);
  height: 70px;
  z-index: 99;
  margin-top: 40px;
`;

export const Button = styled.div<{ bgcolor?: string; color?: string }>`
  width: 82px;
  height: 46px;
  background: ${({ bgcolor }) => (bgcolor ? bgcolor : "#f5f5f5")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : "#212121")};
  border-radius: 8px;
  cursor: pointer;
`;

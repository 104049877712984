import React, { useState, FormEvent, ChangeEvent, useRef } from "react";
import { Box, Dialog, FormControl } from "@material-ui/core";

import { StyledDialogTitle } from "app/components/info-dialog/dialogTitle";
import { SubmitForm } from "app/components/submit-form";
import { Form } from "app/components/form";
import { Button, Input, Label } from "./styles";
import { CreatePersonFieldsEnum } from "app/components/tab-wrapper/types/create-person-fields-Interface";
import {
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useUploadAvatarMutation,
} from "feature/person/api";
import coinsIcon from "app/asset/icons/coins.svg";
import diamondsIcon from "app/asset/icons/diamonds.svg";
import { Person } from "feature/person/models";
import {
  CreatePersonRequestInterface,
  UpdatePersonRequestInterface,
} from "feature/person/api/types";
interface PersonModalProps {
  isOpen: boolean;
  onClose(): void;
  initValues?: Person | null;
  title?: string;
  mainButtonText?: string;
}

export const PersonModal: React.FC<PersonModalProps> = ({
  isOpen,
  onClose,
  initValues,
  title,
  mainButtonText,
}) => {
  const [createPerson, { isLoading: isLoadingCreate, error: isErrorCreate }] =
    useCreatePersonMutation();
  const [updatePerson, { isLoading: isLoadingUpdate, error: isErrorUpdate }] =
    useUpdatePersonMutation();
  const [uploadAvatar, { isLoading: isLoadingUpload, error: isErrorUpload }] =
    useUploadAvatarMutation();
  const [coins, setCoins] = useState(initValues ? initValues.coins : 0);
  const [diamonds, setDiamonds] = useState(
    initValues ? initValues.diamonds : 0
  );

  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  function isUpdatePersonRequest(
    request: CreatePersonRequestInterface | UpdatePersonRequestInterface,
    initValues?: Person | null
  ): request is UpdatePersonRequestInterface {
    if (!initValues) return false;
    return (
      "id" in initValues &&
      typeof (initValues as UpdatePersonRequestInterface).id === "number"
    );
  }

  const handleSubmit = (e: FormEvent<HTMLElement>) => {
    let request: CreatePersonRequestInterface | UpdatePersonRequestInterface = {
      [CreatePersonFieldsEnum.COINS]: coins,
      [CreatePersonFieldsEnum.DIAMONDS]: diamonds,
      //@ts-ignore
      [CreatePersonFieldsEnum.NAME]: e.target.name.value,
      //@ts-ignore
      [CreatePersonFieldsEnum.EMAIL]: e.target.email.value,

      [CreatePersonFieldsEnum.AVATAR_URL]: initValues?.avatarUrl,
    };

    if (avatarFile) {
      const newAvatar = new FormData();
      newAvatar.append("file", avatarFile);
      uploadAvatar(newAvatar).then((data) => {
        if (typeof data === "string") {
          request[CreatePersonFieldsEnum.AVATAR_URL] = data;
          if (initValues && isUpdatePersonRequest(request, initValues)) {
            request.id = initValues.id;
            updatePerson(request);
          } else {
            createPerson(request);
          }
        }
      });
    } else {
      if (initValues && isUpdatePersonRequest(request, initValues)) {
        request.id = initValues.id;
        updatePerson(request);
      } else {
        createPerson(request);
      }
    }
    e.preventDefault();
    onClose();
  };

  const handleChangeCoins = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) setCoins(value);
  };

  const handleChangeDiamonds = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) setDiamonds(value);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <Box sx={{ m: 2 }}>
        <FormControl component="fieldset">
          <StyledDialogTitle>{title || "Добавить личность"}</StyledDialogTitle>
          <input
            type="file"
            onChange={(e: any) => {
              setAvatarFile(e.target.files[0]);
            }}
          />
          <Form onSubmit={handleSubmit}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <img src={coinsIcon} />
              <Input
                width="104px"
                height="38px"
                type="number"
                value={coins}
                onChange={handleChangeCoins}
                background="#F5F5F5;"
              />
              <img src={diamondsIcon} />
              <Input
                width="104px"
                height="38px"
                value={diamonds}
                onChange={handleChangeDiamonds}
                type="number"
                background="#F5F5F5;"
              />
            </Box>
            <Label htmlFor="name">Имя</Label>
            <Input
              id="name"
              required
              placeholder="Введите имя"
              defaultValue={initValues?.name}
            />
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              required
              placeholder="Введите email"
              defaultValue={initValues?.email}
            />
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Отмена
                  </Button>
                </Box>
                <SubmitForm
                  error={isErrorCreate || isErrorUpdate}
                  isLoading={isLoadingCreate || isLoadingUpdate}
                >
                  <Box>{mainButtonText || "Добавить"}</Box>
                </SubmitForm>
              </Box>
            </Box>
          </Form>
        </FormControl>
      </Box>
    </Dialog>
  );
};

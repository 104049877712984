import { Box, Switch, TextField } from "@material-ui/core";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import React, { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TimePickerWrapper, StyledTimePicker } from "./styles";
import { RegularText } from "../add-bot/components/card-event/styles";
import TimePicker from "@mui/lab/TimePicker";

interface ScheduleDatePickerProps {
  isAllDay: boolean;
  handleSwitchAllDay: () => void;
}

export const ScheduleDatePicker: React.FC<ScheduleDatePickerProps> = ({
  isAllDay,
  handleSwitchAllDay,
}) => {
  const [date, changeDate] = useState(moment().toISOString());
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endTime, setEndTime] = React.useState<Date | null>(new Date());
  const handleChange = () => {};
  return (
    <Box mt={2} display="flex">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          label="Week picker"
          value={date}
          onChange={handleChange}
          inputFormat="'Week of' MMM d"
          renderInput={(params) => <TextField />}
        />
        <TimePickerWrapper>
          <Box mb={3}>
            <RegularText color="white" fontSize={20} bold={true}>
              {moment(date).format("D MMM. YYYY")}
            </RegularText>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <RegularText color="white" fontSize={20}>
              Весь день
            </RegularText>
            <Switch checked={isAllDay} onChange={handleSwitchAllDay} />
          </Box>
          {!isAllDay && (
            <>
              <StyledTimePicker mr={1} mb={2}>
                <TimePicker
                  value={startDate}
                  label="Начало"
                  onChange={(newValue: any) => setStartDate(newValue)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </StyledTimePicker>
              <StyledTimePicker mr={1}>
                <TimePicker
                  value={endTime}
                  label="Конец"
                  onChange={(newValue: any) => setEndTime(newValue)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </StyledTimePicker>
            </>
          )}
        </TimePickerWrapper>
      </LocalizationProvider>
    </Box>
  );
};

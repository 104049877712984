import React, { FormEvent } from "react";

import { FormContainer } from "./styles";

interface FormProps {
  onSubmit(e: FormEvent): void;
  className?: string;
}

export const Form: React.FC<FormProps> = ({
  onSubmit,
  className,
  children,
}) => (
  <FormContainer className={className} onSubmit={onSubmit}>
    {children}
  </FormContainer>
);

import styled from "styled-components";
import { FormInput, FormSelect } from "shards-react";

export const StyledInput = styled(FormInput)`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 8px;
  width: 272px;
  min-height: 50px;
  padding: 15px;
  margin: 15px 0;
`;

export const LinkText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #0064fa;
  cursor: pointer;
`;

export const StyledSelect = styled(FormSelect)`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 272px;
  padding: 15px 5px;
`;

export const CancelButton = styled.div`
  padding: 15px 16px;
  margin-right: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

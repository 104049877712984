import React, { ChangeEvent } from "react";
import { Box } from "@material-ui/core";
import { NumberInput } from "app/components/styles";

interface FirstStreamSettingProps {
  amount: number | null;
  setAmount: (value: number | null) => void;
}
export const FirstStreamSetting: React.FC<FirstStreamSettingProps> = ({
  amount,
  setAmount,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) >= 0) setAmount(Number(e.target.value));
  };

  return (
    <Box mb={4}>
      <NumberInput
        onChange={handleChange}
        type="number"
        defaultValue="0"
        value={amount}
      />
    </Box>
  );
};

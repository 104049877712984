import { format } from "date-fns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import AppTypes from "AppTypes";
import { AppSlice } from "./types";

const initialState: AppSlice = {
  tab: 0,
  selectedDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
  },
});

export const { setActiveTab, setSelectedDate } = appSlice.actions;

export const selectActiveTab = (state: AppTypes.Redux.AppRootState) =>
  state.app.tab;
export const selectSelectedDate = (state: AppTypes.Redux.AppRootState) =>
  state.app.selectedDate;

export const appReducer = appSlice.reducer;

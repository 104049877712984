import React from "react";
import archiveIcon from "app/asset/icons/table/archiveIcon.svg";
import copyIcon from "app/asset/icons/table/copyIcon.svg";
import editIcon from "app/asset/icons/table/editIcon.svg";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Icon } from "./styles";

interface OptionsCellProps {
  onDeleteClick: (botId: number) => void;
  onEditClick: (botId: number) => void;
  botId: number;
}
export const OptionsCell: React.FC<OptionsCellProps> = ({
  onDeleteClick,
  onEditClick,
  botId,
}) => {
  return (
    <Box display="flex" width="100px" justifyContent="space-between">
      <Icon src={archiveIcon} onClick={() => onDeleteClick(botId)} />
      <Icon src={copyIcon} />
      <Link onClick={() => onEditClick(botId)} to={`/bots/${botId}/edit`}>
        <Icon src={editIcon} />
      </Link>
    </Box>
  );
};

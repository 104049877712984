import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { selectPerson } from "feature/person/store";
import { useAppSelector } from "app/store-setup/utils";
import { Name, Link, Count, Label } from "./styles";
import coinsIcon from "app/asset/icons/coins.svg";
import diamondsIcon from "app/asset/icons/diamonds.svg";
import { RegularText } from "../add-bot/components/card-event/styles";
import { PersonModal } from "../tab-wrapper/components/person-modal";

export const Person: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { person } = useAppSelector(selectPerson);
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);

  const handlePersonModalClose = () => {
    setIsPersonModalVisible(false);
  };

  const handleClickEdit = () => {
    setIsPersonModalVisible(true);
  };

  return (
    <>
      <PersonModal
        isOpen={isPersonModalVisible}
        onClose={handlePersonModalClose}
        title="Редактировать личность"
        mainButtonText="Сохранить"
        initValues={person}
      />
      <Box m={3}>
        <Box display="flex" alignItems="center">
          <img src={person?.avatarUrl} width={128} height={128} />
          <Box ml={3}>
            <Box my={2}>
              <Name>{person?.name}</Name>
            </Box>
            <Box my={2} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <img src={coinsIcon} />
                <Count>{(person?.coins || 0) / 1000}k</Count>
              </Box>
              <Box display="flex" alignItems="center" ml={3}>
                <img src={diamondsIcon} />
                <Count>{(person?.diamonds || 0) / 1000}k</Count>
              </Box>
            </Box>
            <Link onClick={handleClickEdit}>Редактировать личность</Link>
          </Box>
        </Box>
        <Box>
          <Box my={1} maxWidth={300}>
            <Label>Email</Label>
            <RegularText color="#212121" fontSize={16}>
              {person?.email}
            </RegularText>
          </Box>
        </Box>
      </Box>
    </>
  );
};

import AppTypes from "AppTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Person } from "feature/person/models";
import { Persons, PersonSlice } from "./types";

const initialState: PersonSlice = {
  person: null,
  persons: {},
};

const personSlice = createSlice({
  name: "person",
  initialState,
  reducers: {
    setPerson: (state, action: PayloadAction<Person>) => {
      state.person = action.payload;
    },
    setPersons: (state, action: PayloadAction<Person[]>) => {
      state.persons = action.payload.reduce<Persons>((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
  },
});

export const { setPerson, setPersons } = personSlice.actions;

export const selectPerson = (state: AppTypes.Redux.AppRootState) =>
  state.person;

export const personReducer = personSlice.reducer;

import styled from "styled-components";
import { Button } from "@material-ui/core";

export const StyledButton = styled(Button)<{
  component?: string;
  disabled?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.c4} !important;
  font-family: ${({ theme }) =>
    theme.fontFamily.sfProDisplaySemibold} !important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.c6 : theme.colors.c5} !important;
`;

import React, { useState } from "react";
import { Avatar, Table, TableCell, TableRow } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { TableSortLabelEnum } from "../table/tableCellWithSort/table-sort-label-enum";
import { rows } from "./rows";

import { Container } from "./styles";
import { Person } from "feature/person/models";
import { TableProps } from "app/api/types/table-props";
import { OrderDirectionEnum } from "app/api/types";
import { FilterFormFieldsInterface } from "../filter/types/filter-form-fields";
import { AppTableHead } from "app/components/table/table-head";
import { AppTableBody } from "app/components/table/tableBody";
import { TableCellWithSort } from "app/components/table/tableCellWithSort";
import { OptionsCell } from "./components/options-cell";
import { Link } from "react-router-dom";
import { setPerson } from "feature/person/store";
import { useDispatch } from "react-redux";
import { useArchivePersonMutation } from "feature/person/api";

export const PersonList: React.FC<TableProps<Person>> = ({
  list,
  onDeleteClick,
  onSortChange,
  onFilterChange,
}) => {
  const [isAsc, setIsAsc] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [deletePerson, { isLoading, error }] = useArchivePersonMutation();

  const handleSortCLick = (order: string) => {
    setIsAsc(!isAsc);

    const direction = isAsc ? OrderDirectionEnum.ASC : OrderDirectionEnum.DESC;

    if (onSortChange) onSortChange(order, direction);
  };

  const handleFilterCLick = (
    field: string,
    values: FilterFormFieldsInterface
  ) => {
    if (onFilterChange) onFilterChange(field, values);
  };

  const handleDeleteClick = (personId: number) => {
    deletePerson(personId);
  };

  const handlePersonClick = (person: Person) => {
    dispatch(setPerson(person));
  };

  return (
    <Container>
      <TableContainer>
        <Table aria-label="simple table">
          <AppTableHead>
            <TableRow>
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Id"
                field="id"
                onSortClick={handleSortCLick}
              />
              <TableCell />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Имя пользователя"
                field="name"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
              <TableCellWithSort
                direction={
                  isAsc ? TableSortLabelEnum.ASC : TableSortLabelEnum.DESC
                }
                name="Email"
                field="email"
                onSortClick={handleSortCLick}
                onFilterClick={handleFilterCLick}
              />
            </TableRow>
          </AppTableHead>
          <AppTableBody>
            {list?.list &&
              rows(list.list).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <Link to={`/persons/${row.id}`}>
                      <Avatar
                        onClick={() => handlePersonClick(row)}
                        src={row.avatarUrl}
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <OptionsCell
                      personId={row.id}
                      onEditClick={() => handlePersonClick(row)}
                      link={`/persons/${row.id}`}
                      onDeleteClick={handleDeleteClick}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </AppTableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

import { Card } from "@material-ui/core";
import { FormRadio } from "shards-react";
import styled from "styled-components";

export const TextArea = styled.textarea`
  border: 0.5px solid #d2d2d2;
  width: 100%;
  min-height: 50px;
  resize: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
`;

export const Counter = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0064fa;
  background: #f5f5f5;
  width: 5px;
  padding: 4px 8px;
  border-radius: 50%;
`;

export const TextEditorTextOption = styled.div`
  padding: 4px;
  width: 19px;
  height: 19px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:hover {
    background: #dedede;
  }
`;

export const AddOption = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  letter-spacing: -0.24px;
  color: #0064fa;
  cursor: pointer;
`;

export const BoldText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
`;

export const DragIcon = styled.div`
  margin-top: 120px;
  text-align: center;
  cursor: pointer;
`;

export const RegularText = styled.span<{
  fontSize?: number;
  color?: string;
  bold?: boolean;
  margin?: string;
}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ fontSize }) => fontSize || 14}px;
  line-height: 16px;
  color: ${({ color }) => (color ? color : "black")};
  margin: ${({ margin }) => (margin ? margin : "0")};
`;

export const TimePeekCard = styled(Card)`
  height: 90px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: baseline;
`;

export const EventCard = styled(Card)`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 12px 12px;
`;

export const StyledFormRadio = styled(FormRadio)`
  width: 20px;
  height: 20px;
`;

import { FormInput } from "shards-react";
import TimelineDot from "@mui/lab/TimelineDot";
import styled from "styled-components";
import TimelineItem from "@mui/lab/TimelineItem";

export const Events = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-evenly;
`;

export const MainButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  color: #0064fa;
  background: #f7faff;
  border: 1px solid #0064fa;
  box-sizing: border-box;
  width: 647px;
  height: 72px;
  border-radius: 8px;
  margin-top: 32px;
  &:hover {
    cursor: pointer;
  }
`;

export const NameInput = styled(FormInput)`
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 20px;
`;

export const PauseTimelineDot = styled(TimelineDot)`
  padding: 0 !important;
`;

export const StyledTimelineItem = styled(TimelineItem)`
  &:before {
    -webkit-flex: 0 !important;
    flex: 0 !important;
    -ms-flex: 0 !important;
  }
`;

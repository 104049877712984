import React from "react";
import archiveIcon from "app/asset/icons/table/archiveIcon.svg";
import copyIcon from "app/asset/icons/table/copyIcon.svg";
import editIcon from "app/asset/icons/table/editIcon.svg";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

interface OptionsCellProps {
  onDeleteClick: (personId: number) => void;
  onEditClick: () => void;
  link?: string;
  personId: number;
}
export const OptionsCell: React.FC<OptionsCellProps> = ({
  onDeleteClick,
  onEditClick,
  link,
  personId,
}) => {
  return (
    <Box display="flex" width="100px" justifyContent="space-between">
      <img
        style={{ cursor: "pointer" }}
        src={archiveIcon}
        onClick={() => onDeleteClick(personId)}
      />
      <img src={copyIcon} />
      {link ? (
        <Link to={link}>
          <img src={editIcon} onClick={onEditClick} />
        </Link>
      ) : (
        <img src={editIcon} onClick={onEditClick} />
      )}
    </Box>
  );
};

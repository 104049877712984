import styled from "styled-components";

export const Content = styled.div`
  margin: 0 auto;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0 0 30px;
  box-sizing: border-box;
  z-index: 0;
  color: ${({ theme }) => theme.colors.c1};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.c1};
  }

  a:visited {
    text-decoration: none;
  }

  a:focus {
    outline: none;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.c1};
    -webkit-transition: all 0s 50000s;
  }
`;

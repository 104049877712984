import { makeStyles, Typography } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const StyledTypography = styled(Typography)<{ component: string }>`
  font-family: ${({ theme }) => theme.fontFamily.sfProDisplayBold};
  color: ${({ theme }) => theme.colors.c5} !important;
`;

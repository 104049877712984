import { configureStore } from "@reduxjs/toolkit";

import { api } from "app/api";
import { config } from "app/config";
import { authReducer } from "app/feature/auth/store";
import { appReducer } from "app/store";
import { botReducer } from "feature/bot/store";
import { errorHandlerMiddleware } from "./error-handler";
import { personReducer } from "feature/person/store";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    app: appReducer,
    bot: botReducer,
    person: personReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          config,
        },
      },
    }).concat([api.middleware, errorHandlerMiddleware]),
});

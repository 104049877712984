import * as React from "react";
import {
  useField as useFinalFormField,
  useForm as useFinalFormForm,
} from "react-final-form-hooks";
import { useState } from "react";

import { SubmissionFormErrors } from "./types";

const useForm = <T extends {}>(initialValues?: T) => {
  let submitErrors = React.useRef({});
  const onSubmit = () => submitErrors.current;

  const { form, values } = useFinalFormForm<T>({ onSubmit, initialValues });
  let { hasSubmitErrors, hasValidationErrors, dirtySinceLastSubmit } =
    form.getState();

  const useField = (fieldName: keyof T, validator?: (value: any) => any) => {
    const field = useFinalFormField(fieldName as string, form, validator);
    const [previousValue, setPreviousValue] = useState(field.input.value);
    let errors = false;

    if (
      field.input.value &&
      !field.meta.active &&
      field.meta.touched &&
      field.meta.error
    ) {
      errors = true;
    }

    if (
      field.input.value &&
      field.meta.error &&
      field.meta.active &&
      previousValue === field.input.value
    ) {
      errors = true;
    }

    if (previousValue !== field.input.value && !field.meta.active) {
      errors = false;
      setPreviousValue(field.input.value);
    }

    if (
      !field.meta.error &&
      field.meta.active &&
      previousValue !== field.input.value
    ) {
      setPreviousValue(field.input.value);
    }

    return { field, errors };
  };

  const setSubmissionErrors = React.useCallback(
    (errors: SubmissionFormErrors<T>) => {
      submitErrors.current = errors;
      form.submit();
    },
    [form]
  );

  const initialize = (initialValues: T) => form.initialize(initialValues);

  return {
    form,
    values,
    useField,
    meta: {
      hasSubmitErrors,
      hasValidationErrors,
      dirtySinceLastSubmit,
      setSubmissionErrors,
      initialize,
    },
  };
};

export default useForm;

import styled from "styled-components";
import { Button } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  padding: 0 !important;
  min-width: 24px !important;

  svg {
    fill: ${({ theme }) => theme.colors.c5} !important;
  }
`;

import React from "react";
import { Box } from "@material-ui/core";
import { StyledLeftNav } from "./styles";
import menu from "app/asset/icons/menu.svg";

export const LeftNav: React.FC = () => (
  <Box>
    <StyledLeftNav>
      <img src={menu} />
    </StyledLeftNav>
  </Box>
);

import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Name } from "app/components/person/styles";
import { useAppSelector } from "app/store-setup/utils";
import { selectPerson } from "feature/person/store";
import { Badge } from "../edit-bot/components/card-change-event/styles";

interface PersonRowProps {
  personIds: number[];
}

export const PersonRow: React.FC<PersonRowProps> = ({ personIds }) => {
  const { persons } = useAppSelector(selectPerson);

  const [selectedPersons, setSelectedPersons] = useState(
    personIds.map((personId) => persons[personId])
  );

  useEffect(() => {
    setSelectedPersons(personIds.map((personId) => persons[personId]));
  }, [personIds]);

  console.log({ personChangeROw: { personIds, selectedPersons, persons } });
  return (
    <Box display="flex" justifyContent="space-between">
      {selectedPersons.map((person, index) => (
        <Badge key={index}>
          <img src={person?.avatarUrl} width={20} height={20} />
          <Name>{person?.name}</Name>
        </Badge>
      ))}
    </Box>
  );
};

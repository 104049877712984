import * as LocalStorage from "local-storage";

import { config } from "app/config";

export const setToken = (token: string): boolean =>
  LocalStorage.set(config.localStorageKey.authToken, token);

export const getToken = (): string | undefined =>
  LocalStorage.get(config.localStorageKey.authToken);

export const resetToken = () =>
  LocalStorage.remove(config.localStorageKey.authToken);

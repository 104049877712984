import React from "react";
import { Badge, MessagesBlock } from "../../styles";

interface CardChangeEventProps {
  messages: string[];
}

export const Messages: React.FC<CardChangeEventProps> = ({ messages }) => {
  return (
    <MessagesBlock>
      {messages?.map((message, index) => (
        <Badge key={index}>{message}</Badge>
      ))}
    </MessagesBlock>
  );
};
